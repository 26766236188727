import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createStore,combineReducers} from 'redux';
import userDetailsreducer from "./store/reducers/userDetails";
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
// import configureStore from './store';
import './index.less';
import 'antd/dist/antd.css';


const rootReducer = combineReducers({
  userDetails:userDetailsreducer,
})
const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  
  document.getElementById('root')
);
