import React, { Component } from 'react';

// import { Button, Modal } from 'antd';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

class ApptSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
        }
    }

    handleSearchInput = (e) => {
        let searchText = e.target.value;
        this.setState({searchText : searchText})
        this.props.handleSearchResult(searchText)
    }
    handleSearchAppointments = () =>{
        this.props.handleFinalSearchResult()
    }

    render() {
        return (
            <Paper component="form" className="searchRoot">
                <InputBase
                    className="searchInput"
                    placeholder="Enter Text"
                    value={this.state.searchText}
                    onChange={this.handleSearchInput}
                // inputProps={{ 'aria-label': 'search google maps' }}
                />
                <IconButton onClick={this.handleSearchAppointments}
                    className="searchiconButton"
                    aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Paper>
        );
    }
}

export default ApptSearch;
