import React, { Component,createRef } from 'react';
import Spinner from 'react-spinner';
import classNames from 'classnames';

import AccCore from 'opentok-accelerator-core';
import 'opentok-solutions-css';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import moment from 'moment';
import logo from '../assets/images/sgm_analytics.png';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import config from '../config.json';
import { connect } from 'react-redux';
import * as actionTypes from "../store/actions"
import { Button, Modal, Result, Row, Col, Statistic } from 'antd';
import Topnav from "../components/topNav";
import Draggable from 'react-draggable';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../App.css';
import "../css/appointment.css";
import photoCamera from "../assets/images/photo-camera.png";
// import { useScreenshot, createFileName } from "use-react-screenshot";
// import Screenshot from 'react-screenshots'
// import 'react-screenshots/dist/react-screenshots.css'
import html2canvas from 'html2canvas';
import MeetingDetails from "../components/meeting"


// disableBrowserBackButton =(event) =>{
//     event.preventDefault();
// };
let otCore;
// let ref = createRef(null);
//         let [image, takeScreenShot] = useScreenshot({
//             type: "image/jpeg",
//             quality: 1.0
//         });

// const otCoreOptions = {
//     credentials: {
//         apiKey: config.apiKey,
//         sessionId: config.sessionId,
//         token: config.token,
//     },
//     // A container can either be a query selector or an HTML Element
//     streamContainers(pubSub, type, data, stream) {
//         return {
//             publisher: {
//                 camera: '#cameraPublisherContainer',
//                 screen: '#screenPublisherContainer',
//             },
//             subscriber: {
//                 camera: '#cameraSubscriberContainer',
//                 screen: '#screenSubscriberContainer',
//             },
//         }[pubSub][type];
//     },
//     controlsContainer: '#controls',
//     packages: ['textChat', 'screenSharing', 'annotation'],
//     communication: {
//         callProperties: null, // Using default
//     },
//     textChat: {
//         name: config.userName, // eslint-disable-line no-bitwise
//         // name: "vijay", 
//         waitingMessage: 'Messages will be delivered when other users arrive',
//         container: '#chat',


//     },

//     // const TextChatAccPack = require('opentok-text-chat');
//     screenSharing: {
//         extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
//         annotation: true,
//         externalWindow: false,
//         dev: true,
//         screenProperties: {
//             insertMode: 'append',
//             width: '100%',
//             height: '100%',
//             showControls: false,
//             style: {
//                 buttonDisplayMode: 'off',
//             },
//             videoSource: 'window',
//             fitMode: 'contain' // Using default
//         },
//     },
//     annotation: {
//         absoluteParent: {
//             publisher: '.App-video-container',
//             subscriber: '.App-video-container'
//         }
//     },
// };

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
    const { active, meta, localAudioEnabled, localVideoEnabled } = state;
    const sharingScreen = meta ? !!meta.publisher.screen : false;
    const viewingSharedScreen = meta ? meta.subscriber.screen : false;
    const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
    const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
    const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
    const screenshareActive = viewingSharedScreen || sharingScreen;
    return {
        controlClass: classNames('App-control-container', { hidden: !active }),
        localAudioClass: classNames('ots-video-control circle audio', { hidden: !active, muted: !localAudioEnabled }),
        localVideoClass: classNames('ots-video-control circle video', { hidden: !active, muted: !localVideoEnabled }),
        localCallClass: classNames('ots-video-control circle end-call', { hidden: !active }),
        cameraPublisherClass: classNames('video-container', { hidden: !active, small: !!activeCameraSubscribers || screenshareActive, left: screenshareActive }),
        screenPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
        cameraSubscriberClass: classNames('video-container', { hidden: !active || !activeCameraSubscribers },
            { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive },
            { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
            { small: screenshareActive }
        ),
        screenSubscriberClass: classNames('video-container', { hidden: !viewingSharedScreen || !active }),
    };
};

const connectingMask = () =>
    <div className="App-mask">
        <Spinner />
        <div className="message with-spinner">Connecting</div>
    </div>;



class VideoCalling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            chatDisplay: 'none',
            connected: false,
            active: false,
            publishers: null,
            subscribers: null,
            meta: null,
            appt: {},
            activeAppt:[],
            userName:"",
            localAudioEnabled: true,
            localVideoEnabled: true,
            isInvitationModalVisible: false,
            isMeetingInfoModalVisible: false,
            isListOfApptModalVisible: false,
            urlLink: "",
            userType: "",
            disabled:false,
            // imagewidth: window.innerWidth,
            // imageheight: window.innerHeight,
            otCoreOptions: {
                credentials: {
                    apiKey: localStorage.getItem('apiKey'),
                    sessionId:  localStorage.getItem('sessionId'),
                    token: localStorage.getItem('token'),
                },
                // A container can either be a query selector or an HTML Element
                streamContainers(pubSub, type, data, stream) {
                    return {
                        publisher: {
                            camera: '#cameraPublisherContainer',
                            screen: '#screenPublisherContainer', 
                        },
                        subscriber: {
                            camera: '#cameraSubscriberContainer',
                            screen: '#screenSubscriberContainer',
                        },
                    }[pubSub][type];
                },
                controlsContainer: '#controls',
                packages: ['textChat', 'screenSharing', 'annotation'],
                communication: {
                    // autoSubscribe: false,
                    callProperties: null, // Using default
                },
                textChat: {
                    name: localStorage.getItem('userName'), // eslint-disable-line no-bitwise
                    
                    waitingMessage: 'Messages will be delivered when other users arrive',
                    container: '#chat',
                    limitCharacterMessage: 300,
                    alwaysOpen: true,


                },

                // const TextChatAccPack = require('opentok-text-chat');
                screenSharing: {
                    extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
                    annotation: true,
                    externalWindow: false,
                    dev: true,
                    screenProperties: {
                        insertMode: 'append',
                        width: '100%',
                        height: '100%',
                        showControls: true,
                        style: {
                            buttonDisplayMode: 'off',
                        },
                        videoSource: 'window',
                        fitMode: 'contain' // Using default
                    },
                },
                annotation: {
                    absoluteParent: {
                        publisher: '.App-video-container',
                        subscriber: '.App-video-container'
                    }
                },
            }
        };
        this.startCall = this.startCall.bind(this);
        // this.endCall = this.endCall.bind(this);
        this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
        this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    }

    componentDidMount() {
        
        const userType = localStorage.getItem('userType');
        let userName = localStorage.getItem('userName');
        this.setState({userName})
        
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
            history.go(1);
        };
        let appt= {}
        if(userType !== "6"){
            let appt = JSON.parse(localStorage.getItem('appt'));
            this.setState({ appt, userType })
        }
        
       
   
        // if(this.props.loginStatus === "false"){
        const loginStatus = localStorage.getItem('loginStatus');
        if (loginStatus === null) {
            Modal.error({
                title: "Authentication Error",
                content: "Please Login",
                onOk: () => this.props.history.push("/")
            });
        } else {
            otCore = new AccCore(this.state.otCoreOptions);
          
            otCore.connect().then(() => this.setState({ connected: true }));
            const events = [
                'subscribeToCamera',
                'unsubscribeFromCamera',
                'subscribeToScreen',
                'unsubscribeFromScreen',
                'startScreenShare',
                'endScreenShare',
                // 'startTextXhat',
                // 'endTextChat'
            ];

            events.forEach(event => otCore.on(event, ({ publishers, subscribers, meta }) => {
                this.setState({ publishers, subscribers, meta });
            }));
        }

       
    }

    // endTextChat = () => {
    
    //    this.setState({ chatDisplay: 'none' })
    // }
    handleStartCall = () =>{
        this.startCall()
        this.setState({disabled:true})
    }

    startCallMask = () =>{
    return(
    <div className="App-mask">
        <button className="message button clickable videoStartButton" 
        disabled ={this.state.disabled}
        onClick={this.handleStartCall}>Click to Start Call </button>
    </div>)}

    startCall() {
       

        otCore.startCall()
            .then(({ publishers, subscribers, meta }) => {
                
                    this.setState({ publishers, subscribers, meta, active: true });
            }).catch(error => {});
    }
    // startTextXhat = () => {
    //     
    //     this.setState({ chatDisplay: 'block' })
    // }
    showTextChat = () => {
        this.setState({chatDisplay:"block"});
      
    }

    endCall = () => {
        this.setState({ chatDisplay: 'none' })

        // otCore.session.disconnect()
        otCore.endCall();
        this.setState({ active: false,disabled:false });
    }
    endCallLink = () => {
        this.setState({ chatDisplay: 'none' })

        otCore.session.disconnect()
        otCore.endCall();
        this.setState({ active: false });
    }

    handleEndCall = () => {
        // this.unsubscribeFromCamera();
        // this.unsubscribeFromScreen();
        // this.endScreenShare();
        //  if(this.endCall() === null){
        if (this.state.connected === true) {
            this.endCallLink();
        }
        this.props.history.push("/Appointment");

        //  }
        // if (otCore.session) {
        //     if (publisher) {
        //         otCore.session.unpublish(publisher);
        //     }
        //     otCore.session.disconnect();
        // }
        // otCore.disconnect().then(() => this.setState({ connected: false }));
    }

    toggleLocalAudio() {
        otCore.toggleLocalAudio(!this.state.localAudioEnabled);
        this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
    }

    toggleLocalVideo() {
        otCore.toggleLocalVideo(!this.state.localVideoEnabled);
        this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
    }
    handleInviteLink = () => {
        
        let appt = this.state.appt
       
        let appointmentId = appt.appointmentId
        
        let urlText = ""
       if( appt.Organizer === undefined){
            urlText = `http://izoom.ids-group.co/#/Adhoc?appointmentId=${appointmentId}`
       }else{
            urlText = `http://izoom.ids-group.co/#/AdhocMeet?appointmentId=${appointmentId}`
       }
        
        this.setState({ isInvitationModalVisible: true, urlLink: urlText })
    }
    handleMeetingInfo = () => {
        this.setState({ isMeetingInfoModalVisible: true })
    }
    handleMeetingInfoCancel = () => {
        this.setState({ isMeetingInfoModalVisible: false })
    }
    handleMeetingInfoOk = () => {
        this.setState({ isMeetingInfoModalVisible: false })
    }
    handleInvitationModalOk = () => {
        this.setState({ isInvitationModalVisible: false });
    }
    handleInvitationModalCancel = () => {
        this.setState({ isInvitationModalVisible: false });
    }
    handleCopyText = () => {
        // navigator.clipboard.writeText(this.state.urlText)
   
    }
    handleListofAppt = () => {
        const activeAppt = JSON.parse(localStorage.getItem('activeAppt'))
        this.setState({ isListOfApptModalVisible: true ,activeAppt})
     
    }
    handleListofApptCancel = () => {
        this.setState({ isListOfApptModalVisible: false })
    }
    handleListofApptOk = () => {
        this.setState({ isListOfApptModalVisible: false })
    }

    handleSaveChat = () => {
        let chat = [];
        let chatname = [];
        // button.classList.add("capturingButton");
        // ots-message-item
        [...document.querySelectorAll('#chat .ots-message-item')].map((item,index) => {
       
            item.classList.add(`MessageContainer${index}`);
            chatname[index]={};
           
            [...document.querySelectorAll(`.MessageContainer${index} .ots-item-timestamp`)].map((x,index1)  => 
                chatname[index].title = x.innerHTML.replace(/<\/?span[^>]*>/g,""));
           
            [...document.querySelectorAll(`.MessageContainer${index} .ots-item-text span`)].map((x,index2) => {
                // if(x.innerHTML === undefined){
                    
                    chatname[index].msg += x.innerHTML+",";
                // }
            })
            
            

        });
        // [...document.querySelectorAll('.ots-item-text span')].map(x => chat.push(x.innerHTML));
        // [...document.querySelectorAll('.ots-item-timestamp')].map(x => chatname.push(x.innerHTML));
        
   
        let chatList = "";
        chatname.map((row,index) => {
            // if(index<chat.length - 2 && index>3){
              
                if(index !== 0){
                    chatList += `${row.title}:${row.msg.replace(/undefined/g,"")}\n`;
                }
                
            // }
        })
        
    

        const element = document.createElement("a");
        const file = new Blob([chatList], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${this.state.userName}.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        
        // const blob = new Blob(chatList);
        // const fileDownloadUrl = URL.createObjectURL(blob);
        // fileDownloadUrl.download = `${config.userName}.txt`;
        // this.dofileDownload.click(); 
        // document.body.appendChild(fileDownloadUrl); // Required for this to work in FireFox
        // fileDownloadUrl.click();
    }

    componentDidUpdate = () => {
      
        // var button = document.createElement("BUTTON");
        // button.classList.add("capturingButton");
        // button.innerHTML = "Capture Image";
        
        // let subscriberLength = [...document.querySelectorAll('#cameraSubscriberContainer .OT_subscriber')]
        // if(subscriberLength.length !==0){
        //     // $("p:first").addClass("intro");
        //     subscriberLength.map((x,index )=>{
        //         // if(index === subscriberLength.length-1){
        //             x.appendChild(button) 
        //         // }
        //     })
            
        // }
        

        
    }

    // download = (image, { name = "img", extension = "jpg" } = {}) => {
    //     const a = document.createElement("a");
    //     a.href = image;
    //     a.download = createFileName(extension, name);
    //     a.click();
    //   };
    // downloadScreenshot = () => takeScreenShot(ref.current).then(this.download);

    // onScreenshotSave = ({ viewer, dataURL }) => {
    //     
    //   }
    
    //   onScreenshotCancel = () => {
    //     
    //   }
    
    //   onScreenshotOk = ({ dataURL, viewer }) => {
    //    
    //   }

    // TestButton = () => {
    //     html2canvas(document.getElementById("screenshotdiv")).then(canvas => {
    //         document.body.appendChild(canvas);
    //         canvas.toDataURL();
    //    
    //     }
          
    //     ) 
    //   }

    



    handleapptTimeGmt = (time) =>{

    
      
       let appttime =  moment(time).format('MMMM Do YYYY, h:mm:ss a')
        return appttime;

    }

    render() {
        const { connected, active, appt,activeAppt } = this.state;
        const {
            localAudioClass,
            localVideoClass,
            localCallClass,
            controlClass,
            cameraPublisherClass,
            screenPublisherClass,
            cameraSubscriberClass,
            screenSubscriberClass,
        } = containerClasses(this.state);

        let meetDetails = [];
        meetDetails.push(this.state.appt)
      

        // const { imagewidth, imageheight } = this.state

        
        return (
            <div className="App">
                <Topnav display="flex"
                appointments="none"
                admin="none"
                    handleEndCall={this.handleEndCall}
                    handleIviteLink={this.handleInviteLink}
                    handleMeetingInfo={this.handleMeetingInfo}
                    handleListOfAppt={this.handleListofAppt} />
                    <h5 style={{ margin: 20 }}>Welcome <span className="userTitle">{this.state.userName}</span></h5>
               
                <div className="App-main">
                {/* <Screenshot
        image="./demo.png"
        width={imagewidth}
        height={imageheight}
        onSave={this.onScreenshotSave}
        onCancel={this.onScreenshotCancel}
        onOk={this.onScreenshotOk}
      > */}
                    <div className="App-video-container" id="screenshotdiv"
                            // ref={ref}
                            // style={{
                            // // border: "1px solid #ccc",
                            // padding: "10px",
                            // marginTop: "20px"
                            // }}
                            >
                        {!connected && connectingMask()}
                        {connected && !active && this.startCallMask()}
                        {/* <div
                            
                        > */}
                        <Draggable bounds="parent">
                            <div id="cameraPublisherContainer" className={cameraPublisherClass} />
                        </Draggable>
                        <div id="screenPublisherContainer" className={screenPublisherClass} />

                        <div id="cameraSubscriberContainer" className={cameraSubscriberClass} />


                        <div id="screenSubscriberContainer" className={screenSubscriberClass} />
                        <div id="controls" className={controlClass}>
                            <div className={localAudioClass} onClick={this.toggleLocalAudio} />
                            <div className={localVideoClass} onClick={this.toggleLocalVideo} />
                            <div className={localCallClass} onClick={this.endCall} />
                            {/* <button className="screenshotButton" onClick={this.TestButton}>
                                <img src = {photoCamera} alt="camera" />
                            </button> */}
                        </div>
                    </div>
                    {/* </Screenshot> */}

{/* 
                    OT_subscriber */}



                    <div id="chat" className="App-chat-container chatContainer" 
                    
                        // style={{display:this.state.chatDisplay}}
                    >
                    <Button type="primary" onClick = {this.handleSaveChat}>Save Chat</Button>
                    </div>
                </div>
                <Modal title="Invitation Link"
                    visible={this.state.isInvitationModalVisible}
                    className="payConfirmModal"
                    footer={null}
                    onOk={this.handleInvitationModalOk}
                    onCancel={this.handleInvitationModalCancel}>
                    <Result
                        status="success"
                        title="Successfully Generated Invitation Link"
                        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                        extra={[
                            <p>{this.state.urlLink}</p>,
                            <CopyToClipboard text={this.state.urlLink}
                                onCopy={this.handleCopyText}>
                                <Button type="primary">Copy Link</Button>
                            </CopyToClipboard>,

                        ]}
                    />

                </Modal>
                <Modal title="Meeting Info"
                    visible={this.state.isMeetingInfoModalVisible}
                    className="payConfirmModal"
                    footer={null}
                    onOk={this.handleMeetingInfoOk}
                    onCancel={this.handleMeetingInfoCancel}>
                    {appt &&
                    <div>
                    {appt.Organizer === undefined ?
                    <Row gutter={16} className="appointmentMain">
                        

                            <Col span={23} key="Link"
                                className={this.state.userType !== "5" ? "appointmentDoctor" : "appointmentPatient"}
                            >
                                <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]} className="apptMainRow">
                                   <Col span={6}>
                                                    {/* {this.state.userType !== "2" ?
                                                        <Statistic title={appt.appointmentType !== 2 ?
                                                            "Doctor's Name" : "Meeting Organizer"}
                                                            value={appt.drName} valueStyle={{ fontSize: '16px' }} />
                                                        :
                                                        <Statistic title={appt.appointmentType !== 2 ? "Patient's Name" : "Meeting Organizer"}
                                                            value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                                                    } */}

                                                    <Statistic title="Patient's Name"
                                                        value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                {appt.appointmentType !== 2 ?
                                                    <Col span={6}>
                                                        {/* {this.state.userType === "3" ?
                                                            <Statistic title="Patient's Name" value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                                                            :
                                                            <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                                                        } */}
                                                        <Statistic title=
                                                            "Doctor's Name"
                                                            value={appt.drName} valueStyle={{ fontSize: '16px' }} />
                                                    </Col> : null}
                                                <Col span={6}>
                                                    <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                <Col span={6} className="appointmentJoinContainer">
                                                    <Statistic title="Confirmed appointment time" 
                                                    // value={moment(appt.appointmentDateTime).format('MMMM Do YYYY, h:mm:ss a')} 
                                                    value = {this.handleapptTimeGmt(appt.appointmentDateTime)}
                                                    valueStyle={{ fontSize: '16px' }} />
                                                </Col>

                                                {this.state.userType !== "5" ?

                                                    <Col span={6} className = "modCreateStat">
                                                        <Statistic title="Created By" value={appt.createdBy} valueStyle={{ fontSize: '12px' }} />
                                                    </Col> : null}

                                                {this.state.userType !== "5" ?
                                                    <Col span={6} className = "modCreateStat">
                                                        <Statistic title="Created Time" value={moment(appt.createTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '12px' }} />
                                                    </Col> : null}


                                                {this.state.userType !== "5" ?
                                                    <Col span={6} className = "modCreateStat">
                                                        {appt.modBy !== "Unknown" ?
                                                            <Statistic title="Last Modified By" value={appt.modBy} valueStyle={{ fontSize: '12px' }} /> : null}
                                                    </Col> : null}

                                                {this.state.userType !== "5" ?
                                                    <Col span={6} className = "modCreateStat">
                                                        {appt.modTime !== null ?
                                                            <Statistic title="Last Modification Time" value={moment(appt.modTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '12px' }} /> : null}
                                                    </Col>

                                                    : null}

                                    <Col span={24}>
                                        <Statistic title="Purpose" value={appt.data} valueStyle={{ fontSize: '16px' }} />
                                    </Col>

                                </Row>
                            </Col>
                    </Row>:
                    <MeetingDetails 
                    handleUserMeetings = {this.handleUserMeetings}
                        data = {meetDetails}/>
                    }
                    </div>}
                    
                </Modal>
                <Modal title="List of Appt"
                    visible={this.state.isListOfApptModalVisible}
                    className="lostofApptModal"
                    // size = "large"
                    width={isMobile === false ? '80%' : '100%'}
                    footer={null}
                    onOk={this.handleListofApptOk}
                    onCancel={this.handleListofApptCancel}>
                    <Row gutter={16} className="appointmentMain">
                        {activeAppt &&
                            activeAppt.map(( appt,index)=>
                            <Col span={23} key={index}
                                className={this.state.userType !== "5" ? "appointmentDoctor" : "appointmentPatient"}
                            >
                                <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]} className="apptMainRow">
                                <Col span={6}>
                                                    {/* {this.state.userType !== "2" ?
                                                        <Statistic title={appt.appointmentType !== 2 ?
                                                            "Doctor's Name" : "Meeting Organizer"}
                                                            value={appt.drName} valueStyle={{ fontSize: '16px' }} />
                                                        :
                                                        <Statistic title={appt.appointmentType !== 2 ? "Patient's Name" : "Meeting Organizer"}
                                                            value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                                                    } */}

                                                    <Statistic title="Patient's Name"
                                                        value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                {appt.appointmentType !== 2 ?
                                                    <Col span={6}>
                                                        {/* {this.state.userType === "3" ?
                                                            <Statistic title="Patient's Name" value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                                                            :
                                                            <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                                                        } */}
                                                        <Statistic title=
                                                            "Doctor's Name"
                                                            value={appt.drName} valueStyle={{ fontSize: '16px' }} />
                                                    </Col> : null}
                                                <Col span={6}>
                                                    <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                <Col span={6} className="appointmentJoinContainer">
                                                    <Statistic title="Confirmed appointment time" 
                                                    // value={moment(appt.appointmentDateTime).format('MMMM Do YYYY, h:mm:ss a')} 
                                                    value = {this.handleapptTimeGmt(appt.appointmentDateTime)}
                                                    valueStyle={{ fontSize: '16px' }} />
                                                </Col>

                                                {this.state.userType !== "5" ?

                                                    <Col span={6} className = "modCreateStat">
                                                        <Statistic title="Created By" value={appt.createdBy} valueStyle={{ fontSize: '12px' }} />
                                                    </Col> : null}

                                                {this.state.userType !== "5" ?
                                                    <Col span={6} className = "modCreateStat">
                                                        <Statistic title="Created Time" value={moment(appt.createTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '12px' }} />
                                                    </Col> : null}


                                                {this.state.userType !== "5" ?
                                                    <Col span={6} className = "modCreateStat">
                                                        {appt.modBy !== "Unknown" ?
                                                            <Statistic title="Last Modified By" value={appt.modBy} valueStyle={{ fontSize: '12px' }} /> : null}
                                                    </Col> : null}

                                                {this.state.userType !== "5" ?
                                                    <Col span={6} className = "modCreateStat">
                                                        {appt.modTime !== null ?
                                                            <Statistic title="Last Modification Time" value={moment(appt.modTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '12px' }} /> : null}
                                                    </Col>

                                                    : null}

                                    <Col span={24}>
                                        <Statistic title="Purpose" value={appt.data} valueStyle={{ fontSize: '16px' }} />
                                    </Col>

                                </Row>
                            </Col>)}
                            <div className="appointmentsNavConf">
                                <h5 style={{marginTop:'20px',color:"#9255f"}}>
                                    Do you want to End the Session and See all appointments</h5>
                                <div className="listAppointmentsButtons">
                                    <Button type="primary" onClick = {this.handleEndCall}>Yes</Button>
                                    <Button type="primary" onClick = {this.handleListofApptCancel}>No</Button>
                                </div>
                                
                            </div>
                            
                    </Row>
                    </Modal>


            </div>
        );
    }
}
export default VideoCalling;

