import React, { Component } from 'react';

import {
  Row, Col, Button, Tabs,
  Select, Input, Form, Table,
  Space, Result, Modal, Popconfirm, message, Empty,Statistic
} from 'antd';
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import Topnav from "../components/topNav";
import "../css/admin.css";
import moment from "moment";
import ApptSearch from "../components/searchAppt";
import { CreateServices, UserListServices, UserDeleteServices, UsermodServices
,totalListApptServices } from "../services/adminServices";
// import { css } from '@emotion/react';
let countryCodes = require('country-codes-list')

const { TabPane } = Tabs;
const { Option } = Select;

const userTypeSelect = ["Admin", "Doctor", "Consultant", "Employee", "Patient", "Adhoc","Customer Service"]


const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};




class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      autoCompleteResult: [],
      contryPhoneCodes: [],
      iscreateUserVisible: false,
      iscreateStatus: "",
      isdeleteUserVisible: false,
      isdelteStatus: "",
      userListDetails: [],
      modRecord: {},
      ismodRecordOpen: false,
      moduserType: "",
      totalAppt:[],
      searchResultUsers:[],
      userListDetailsdup:[],

      columns: [
        {
          title: 'User Id',
          dataIndex: 'userId',
          key: 'userId',
          align: "center",
        },
        {
          title: 'User Name',
          dataIndex: 'userName',
          key: 'userName',
          align: "center",
          render: (text, index) => <p key={index} style={{ color: "#09255f", margin: '0px' }}>{text}</p>,
        },
        {
          title: 'Email',
          dataIndex: "email",
          key: 'email',
          align: "center",
        },
        {
          title: 'Mobile',
          dataIndex: 'mobile',
          key: 'mobile',
          align: "center",
        },
        {
          title: 'User Type',

          dataIndex: 'userType',
          key: 'userType',
          align: "center",

          render: (text, record, index) => (
            <div key={index}>
              {userTypeSelect[text - 1]}
            </div>
          ),
        },
        {
          title: 'Delete',
          key: 'delete',
          align: "center",
          fixed: 'right',
          render: (text, record, index) => (
            <Space size="middle" key={index}>
              <Popconfirm
                title={`Are you sure to delete this ${record.userName}?`}
                onConfirm={() => this.handleDeleteConfirm(record)}
                onCancel={this.handleDeletecancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link"
                ><DeleteFilled style={{
                  color: "#09255f", fontSize: "16px"
                }} /></Button>


              </Popconfirm>
              <Button type="link" onClick={() => this.handleModUser(record)}
              ><EditFilled style={{
                color: "#09255f", fontSize: "16px"
              }} /></Button>
            </Space>
          ),
        },
      ]
    }

  }
  handleModUser = (record) => {
    
    this.setState({ modRecord: record, ismodRecordOpen: true, moduserType: record.userType })
  }
  handlemodRecordModalOk = () => {
    this.setState({ ismodRecordOpen: false });
  }
  handlemodRecordModalCancel = () => {
    this.setState({ ismodRecordOpen: false });
  }
  onFinish = (values) => {
   
    const logUserId = localStorage.getItem('userId');
    let userDetails = {
      userName: values.userName,
      email: values.email,
      phone: `${values.prefix}${values.phone}`,
      userType: values.userType.toString(),
      logUser : logUserId
    }


    CreateServices(userDetails).then((response) => {
      
 
      if (response.status === 400) {
        Modal.error({
            title: "Error",
            content: response.data,
        });
    } else {
      let userList = {
        criteria: "All",
        userId: "",
        userName: "",
        userType: "",
        phone: "",
        email: ""
      }

    

      if (response.status === 200) {
        // UserListServices(userList).then((response) => {
      

        //   if (response.status === 200) {
        //  
        //     this.setState({ userListDetails: response.data.usersList })

        //   }
        // }), (response) => {
        // }

        UserListServices(userList).then((response) => {
       
          if (response.status === 400) {
            Modal.error({
                title: "Error",
                content: response.data,
            });
        } else {

          if (response.status === 200) {
           
            this.setState({ userListDetails: response.data.usersList, iscreateUserVisible: true, iscreateStatus: "success" })
          }
        }
        })
        // , (response) => {

        // }
      } else {
        this.setState({ iscreateUserVisible: true, iscreateStatus: "error" })
      }
    }
    })
    // , (response) => {
    //   this.setState({ iscreateUserVisible: true, iscreateStatus: "error" })
    // }
  // }
  };
  onmodFinish = (values) => {

    const logUserId = localStorage.getItem('userId');
 
    let moduserDetails = {
      userId: this.state.modRecord.userId,
      userType: values.userType,
      logUser:logUserId
    }

    UsermodServices(moduserDetails).then((response) => {
     
      if (response.status === 400) {
        Modal.error({
            title: "Error",
            content: response.data,
        });
    } else {

      if (response.status === 200) {
    

        // this.setState({ userListDetails: response.data.usersList })
        this.setState({ ismodRecordOpen: false });
        message.success('Suceesfully updated !');
        let userList = {
          criteria: "All",
          userId: "",
          userName: "",
          userType: "",
          phone: "",
          email: ""
        }
        UserListServices(userList).then((response) => {
   
          if (response.status === 400) {
            Modal.error({
                title: "Error",
                content: response.data,
            });
        } else {

          if (response.status === 200) {
          
            this.setState({ userListDetails: response.data.usersList,
              })
          }
        }
        })
        // , (response) => {
        //   message.error('Error in updating user details !');
        // }

      }
    }
    })
    // , (response) => {
    // }
  }

  componentDidMount = () => {
    const myCountryCodesObject = countryCodes.customList('countryCode', `+{countryCallingCode}`)
  
    let contryPhoneCodes = Object.values(myCountryCodesObject)
    let userList = {
      criteria: "All",
      userId: "",
      userName: "",
      userType: "",
      phone: "",
      email: ""
    }
    UserListServices(userList).then((response) => {
     
      if (response.status === 400) {
        Modal.error({
            title: "Error",
            content: response.data,
        });
    } else {

      if (response.status === 200) {
  
        this.setState({ userListDetails: response.data.usersList,
          userListDetailsdup: response.data.usersList })

      }
    }
    })
    // , (response) => {
    // }
    
    totalListApptServices("hello").then((response) => {
      
      if (response.status === 400) {
        Modal.error({
            title: "Error",
            content: response.data,
        });
    } else {

      if (response.status === 200) {
      
        this.setState({ totalAppt: response.data.apptsList})
      }
    }
    })
    // , (response) => {

    // }

    this.setState({ contryPhoneCodes })
  }

  handlecreateUserModalOk = () => {
    this.setState({ iscreateUserVisible: false });
  }
  handlecreateUserModalCancel = () => {
    this.setState({ iscreateUserVisible: false });
  }
  handledeleteUserModalOk = () => {
    this.setState({ isdeleteUserVisible: false });
  }
  handledeleteUserModalCancel = () => {
    this.setState({ isdeleteUserVisible: false });
  }

  handleDeleteConfirm = (record) => {
    let userDetails = {
      userId: record.userId.toString()
    }
    UserDeleteServices(userDetails).then((response) => {
      if (response.status === 400) {
        Modal.error({
            title: "Error",
            content: response.data,
        });
    } else {

      this.setState({
        ...this.state,
        userListDetails: this.state.userListDetails.filter((row) => row.userId !== record.userId),
      })
      message.success('Suceesfully Deleted !');
    }
    })
    // , (response) => {
    //   this.setState({
    //     isdeleteUserVisible: true, isdelteStatus: "error"
    //   })

    // }
  }

  handleDeletecancel = (e) => {

    message.error('Rejected to delete !');
  }

  handleSearchResult = (text) =>{

    let searchText = text;

  

    let apptType =  this.state.userListDetails;

    if(searchText === ""){
        
        this.setState({userListDetails:this.state.userListDetailsdup})
    }else{

      let filteredAppt1 = _.filter(
          apptType,
          (appt) => _.includes(appt.userName.toLowerCase(), searchText.toLowerCase()) === true
      );
      let filteredAppt2 = _.filter(
          apptType,
          (appt) => _.includes(appt.email.toLowerCase(), searchText.toLowerCase()) === true
      );
      let filteredAppt3 = _.filter(
          apptType,
          (appt) => _.includes(appt.mobile, searchText) === true
      );
      let filteredAppt4 = _.filter(
          apptType,
          (appt) => _.includes(appt.userType===1?"admin":appt.userType===2?
          "doctor":appt.userType===3?"consultant":appt.userType===4?"employee":
          appt.userType===5?"patient":appt.userType===6?"adhoc":"cutomer service"
          , searchText.toLowerCase()) === true
      );

      // "Admin", "Doctor", "Consultant", "Employee", "Patient", "Adhoc","Customer Service"
   

      let filteredAppt = [...filteredAppt1, ...filteredAppt2, ...filteredAppt3,...filteredAppt4]

      let uniqueChars = filteredAppt.filter((c, index) => {
        return filteredAppt.indexOf(c) === index;
      });

   

      

      this.setState({searchResultUsers:uniqueChars})
    }


  }
  handleFinalActiveSearchResult = () =>{
        this.setState({userListDetails:this.state.searchResultUsers
        })
  }

  render() {

    let userName = localStorage.getItem("userName")
    return (
      <div>
        <Topnav appointments="flex" display="none" admin="none"
           />
        <h5 style={{ margin: 20 }}>Welcome <span className="userTitle">{userName}</span></h5>
        <Tabs type="card" className="tabForAdmin">
          <TabPane tab="Create Profile" key="1" style={{ padding: "10px", }} >
            <Row>
              <Col xs={23} sm={23} md={18} lg={16} xl={14} style={{ margin: "auto" }}>
                <Form
                  requiredMark={false}
                  layout="vertical"
                  name="register"
                  onFinish={this.onFinish}
                  initialValues={{
                    prefix: '+65',
                  }}
                  size="large"

                  scrollToFirstError
                >
                  <Form.Item
                    name="userName"
                    label="User Name"
                    className="InputLabel"
                    // tooltip="What do you want others to call you?"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your name!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="E-mail"
                    className="InputLabel"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label="Phone Number"
                    className="InputLabel addonStyle"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your phone number!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('phone').replace(/\D/g, "") === getFieldValue('phone')) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('Please enter numbers only'));
                        },
                      }),
                    ]}
                  >
                    <Input maxLength={12}
                      addonBefore={<Form.Item name="prefix" noStyle>
                        <Select
                          style={{
                            width: 'auto',
                          }}
                        >
                          {this.state.contryPhoneCodes &&
                            this.state.contryPhoneCodes.map((code, index) =>
                              <Option value={code} key={index}>{code}</Option>)}

                        </Select>
                      </Form.Item>}
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="userType"
                    label="User Type"
                    className="selectLabel"
                    rules={[
                      {
                        required: true,
                        message: 'Please select the user type!',
                      },
                    ]}

                  >
                    <Select>
                      {userTypeSelect.map((userType, index) =>
                        <Select.Option key={index} value={index + 1}>{userType}</Select.Option>
                      )}

                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="userFormButton">
                    <Button type="primary" htmlType="submit" style={{ margin: "auto" }}>
                      Register
                                </Button>
                  </Form.Item>

                </Form>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="User Profiles" key="2" style={{ padding: "10px", }}>
          <ApptSearch
                                    handleSearchResult={this.handleSearchResult}
                                    handleFinalSearchResult={this.handleFinalActiveSearchResult} />
            {this.state.userListDetails.length !== 0 ?
              <Table className="userTableInfo" columns={this.state.columns} dataSource={this.state.userListDetails} bordered={true} scroll={{ x: true }}
                size="small" sticky /> : <Empty />}
          </TabPane>
{/* 
          <TabPane tab="All appointments" key="3" style={{ padding: "10px", }} >
            <Row gutter={16} className="appointmentMain">
              {this.state.totalAppt.length !== 0 ?
                (this.state.totalAppt.map((appt, index) =>
                  <Col span={20} className="expiredappointmentPatient" key={index}>

                    <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]} className="apptMainRow">
                      <Col span={8}>
                       
                          <Statistic title={appt.appointmentType !== 2 ?
                            "Doctor's Name" : "Meeting Organizer"}
                            value={appt.drName} valueStyle={{ fontSize: '16px' }} />
                          
                      </Col>
                      <Col span={8}>
                     
                          <Statistic title={appt.appointmentType !== 2 ? "Patient's Name" : "Meeting Organizer"}
                            value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                        
                      </Col>
                      {appt.appointmentType !== 2 ?
                        <Col span={8}>
                            <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                        </Col> : null}
                      <Col span={8} className="appointmentJoinContainer">
                        <Statistic title="Appointment Time" value={moment(appt.date).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '16px' }} />
                      </Col>
                      <Col span={16}>
                        <Statistic title="Purpose" value={appt.purpose} valueStyle={{ fontSize: '16px' }} />
                      </Col>
                   

                    </Row>

                    
                  </Col>)) : null}


            </Row>
          </TabPane> */}
          <TabPane tab="settings" key="3">

          </TabPane>

        </Tabs>
        <Modal title="Create User Confirmation"
          visible={this.state.iscreateUserVisible}
          className="payConfirmModal"
          footer={null}
          onOk={this.handlecreateUserModalOk}
          onCancel={this.handlecreateUserModalCancel}>
          <Result
            status={this.state.iscreateStatus}
            title={this.state.iscreateStatus === "success" ? "User Created Successfully"
              : "Error in creating User"}
          />

        </Modal>
        <Modal title="Delete User Confirmation"
          visible={this.state.isdeleteUserVisible}
          className="payConfirmModal"
          footer={null}
          onOk={this.handledeleteUserModalOk}
          onCancel={this.handledeleteUserModalCancel}>
          <Result
            status={this.state.isdelteStatus}
            title={this.state.isdelteStatus === "success" ? "User Delted Successfully"
              : "Error in Delted User"}
          />

        </Modal>
        <Modal title="Modify User Details"
          visible={this.state.ismodRecordOpen}
          destroyOnClose={true}
          className="payConfirmModal"
          footer={null}
          onOk={this.handlemodRecordModalOk}
          onCancel={this.handlemodRecordModalCancel}>
          {/* <Result
            status={this.state.isdelteStatus}
            title={this.state.isdelteStatus === "success" ? "User Delted Successfully"
              : "Error in Delted User"}
          /> */}
          <p className="modUserDetailsModal">User Name : <span>{this.state.modRecord.userName}</span></p>
          <p className="modUserDetailsModal">E-mail : <span>{this.state.modRecord.email}</span></p>
          <p className="modUserDetailsModal">Phone No : <span>{this.state.modRecord.mobile}</span></p>
          {/* <p>Phone No : <span>{record.mobile}</span></p> */}
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
              // prefix: '+65',
            }}
            requiredMark={false}
            onFinish={this.onmodFinish}
            // onFinishFailed={this.onmodFinishFailed}
            scrollToFirstError
            size="large"
          >
            <Form.Item name="userType" label="User Type" className="InputLabel"
              initialValue={this.state.moduserType}
              rules={[
                {
                  required: true,
                  message: 'Please select the user type!',
                },
              ]}
            >
              <Select
                placeholder="Select User Type"
                // onChange={onGenderChange}
                // defaultValue = {this.state.modRecord.userType}
                // value={this.state.modRecord.userType}
                allowClear
              >
                {userTypeSelect.map((userType, index) =>
                  <Select.Option key={index} value={index + 1}>{userType}</Select.Option>
                )}
                {/* <Option value="male">male</Option> */}
                {/* <Option value="female">female</Option>
                <Option value="other">other</Option> */}
              </Select>
            </Form.Item>
            <Form.Item
              className="userFormButton">
              <Button type="primary" htmlType="submit" style={{ margin: "auto" }}>
                Update
                                </Button>
            </Form.Item>
          </Form>

        </Modal>

      </div>
    );
  }
}

export default Admin;