import BaseUrl from "./baseUrl";
import axios from 'axios';

const api = axios.create({
    baseURL: BaseUrl.rootUrl,
});

export async function  CreateServices(userDetails) {
    return api.post('createUser', userDetails,{timeout: 10000}).then(response => {
        
        return response;
    }) .catch((error) => {
    
        
        return error;
      })
}
export async function  UserListServices(userListDetails) {
    return api.post('getUsersList', userListDetails,{timeout: 10000}).then(response => {
       
        return response;
    }) .catch((error) => {
    
       
        return error;
      })
}
export async function  UserDeleteServices(deleteUser) {
    return api.post('delUser', deleteUser,{timeout: 10000}).then(response => {
        
        return response;
    }) .catch((error) => {
    
        
        return error;
      })
}
export async function  UsermodServices(UsermodDetails) {
    return api.post('modUser', UsermodDetails,{timeout: 10000}).then(response => {
       
        return response;
    }) .catch((error) => {
    
        
        return error;
      })
}
export async function  totalListApptServices(UsermodDetails) {
    return api.post('listAppts', UsermodDetails,{timeout: 10000}).then(response => {
     
        return response;
    }) .catch((error) => {
    
       
        return error.response;
      })
}