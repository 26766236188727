import BaseUrl from "./baseUrl";
import axios from 'axios';

const api = axios.create({
    baseURL: BaseUrl.rootUrl,
});

export async function  UserMeetingtServices(MeetDetails) {
    return api.post('getMeetList', MeetDetails,{timeout: 10000}).then(response => {
       
        return response;
    }) .catch((error) => {
    
       
        return error.response;
      })
}

export async function  createMeetingService(MeetDetails) {
    return api.post('createMeet', MeetDetails,{timeout: 10000}).then(response => {
       
        return response;
    }) .catch((error) => {
    
       
        return error.response;
      })
}
export async function  modifyMeetService(MeetDetails) {
    return api.post('modMeet', MeetDetails,{timeout: 10000}).then(response => {
      
        return response;
    }) .catch((error) => {
    
      
        return error.response;
      })
}