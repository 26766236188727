import React, { Component } from 'react';
import { Input, Button, Modal, } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined, LockFilled, LockTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../config.json';


import logo from '../assets/images/sgm_analytics.png';

import "../css/login.css";
import * as actionTypes from "../store/actions"

import { UserLoginService } from "../services/loginService"
// import state from 'opentok-accelerator-core/dist/state';




class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userDetails: {},
            userName: "",
            pswd: "",

        }
    }
    componentDidMount = () => {
        // window.history.forward(); 
  
    }

    // handleKeyDown = (e) => {
    //     let code = (e.keyCode ? e.keyCode : e.which);
    //     if (code === 13) { //Enter keycode
    //         // alert('enter press');
    //         this.handleLogin()
    //     }
    // }

    handleUserName = (e) => {
      
        let username = e.target.value;
        this.setState({ userName: username })

    }

    handlePassword = (e) => {

        let pswd = e.target.value;
        this.setState({ pswd })
    }

    // onKeyDown = () => {
    //     let code = (e.keyCode ? e.keyCode : e.which);
    //         if (code === 13) { //Enter keycode
    //             // alert('enter press');
    //             this.handleLogin()
    //         }
    // }

    handleLogin = () => {

        if (this.state.userName === "" || this.state.pswd === "") {
            Modal.warning({
                title: (this.state.userName === "" && this.state.pswd === "") ? 'No User crendential' :
                    this.state.pswd === "" ? "No password" : "No User name"
                ,
                content: (this.state.userName === "" && this.state.pswd === "") ?
                    'Please enter username and password' :
                    this.state.pswd === "" ? "Please enter the password" :
                        "Please enter the user name",
            });
        } else {

            let userCredentials = {
                userName: this.state.userName,
                pswd: this.state.pswd,
            }

            UserLoginService(userCredentials).then((response) => {

              
                let resultObject = response.data;
                if (resultObject.userId === "0") {
                    Modal.error({
                        title: "Login Error",
                        content: "Credential are wrong",
                    });
                } else
                    if (resultObject.success === "0") {
                        let loginStatus = resultObject.success === "0" ? "true" : "false";
                        config.userName = this.state.userName;
                        config.loginStatus = loginStatus;
                        config.userType = resultObject.userType;
                        config.userId = resultObject.userId
                        localStorage.setItem('loginStatus', loginStatus)
                        localStorage.setItem('userName', this.state.userName)
                        localStorage.setItem("userId",resultObject.userId)
                        localStorage.setItem("userType",resultObject.userType)
                        // this.props.userDetails(
                        //     this.state.userName,
                        //     this.state.pswd,
                        //     loginStatus,
                        //     resultObject.userType
                        //     );
                        this.props.history.push("/Appointment");
                        
                    }

            })

        }

    }


    render() {
        return (
            <div className="login_body">
                
                <div className="LoginBodyMain">
                    <div className="Loginlogo">
                        <img src={logo} alt="logoo" width="auto" />
                    </div>
                    <div className="loginCard">
                        <h5 className="loginHeadingText">Login</h5>
                        <Input placeholder="Username"
                            className="inputUsername"
                            size="large"
                            value={this.state.userName}
                            onPressEnter={this.handleLogin}
                            onChange={this.handleUserName}
                            prefix={<UserOutlined className="site-form-item-icon" />} />
                        <Input.Password
                            placeholder="Password"
                            className="inputPassword"
                            size="large"
                            value={this.state.pswd}
                            onPressEnter={this.handleLogin}
                            onChange={this.handlePassword}
                            prefix={<LockFilled className="site-form-item-icon" />}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                        <Button
                            className="loginSubmitButton"
                            size="large"
                            onClick={this.handleLogin}
                            
                        >Submit</Button>

                    </div>
                </div>

            </div>
        );
    }
}

export default Login;

// const mapStateToProps = state => {
//     return{
//         userName : state.userDetails.userName,
//         pswd : state.userDetails.pswd,
//         loginStatus : state.userDetails.loginStatus,
//         userType : state.userDetails.userType
//     } 
// }
// const mapDispatchToProps = dispatch => {
//     return {
//         userDetails : (userName,pswd,loginStatus,userType) => dispatch({
//             type: actionTypes.USER_LOGIN_CREDENTIAL,
//             userDetails : {
//                 userName : userName,
//                 pswd : pswd,
//                 loginStatus : loginStatus,
//                 userType : userType

//             }


//         })
//     }
// }

// export default connect(mapStateToProps,mapDispatchToProps)(Login);