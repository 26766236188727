import * as actionTypes from "../actions";

const userDetails = {
    
    userName:"",
    pswd:"",
    loginStatus: "false",
    userType : "", 
}
const reducer = (state = userDetails,action) => {
    if(action.type === actionTypes.USER_LOGIN_CREDENTIAL){
        return {
           ...state,
            userName : action.userDetails.userName,
            pswd  : action.userDetails.pswd,
            loginStatus : action.userDetails.loginStatus,
            userType : action.userDetails.userType
        }
    }
    return state;
}
export default reducer;