import React, { Component } from 'react';

import {Button, Modal} from 'antd';

import "../css/timePicker.css";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

class MTimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModTime: null,
            isTimeModalVisible:false,
            timeModalText:null,
        } 
    }

    handleModTimeChange = (time) => {

            let restrictedHour = new Date().getHours();
            let restrictedMinute = new Date().getMinutes();
            let selectedhour = new Date(time).getHours();
           
            let SelectedMiniute = new Date(time).getMinutes();
        
            if(9<=selectedhour&&selectedhour<18){
                if(SelectedMiniute === 0 || SelectedMiniute === 15 ||
                    SelectedMiniute === 30 || SelectedMiniute === 45){
                        if(selectedhour>restrictedHour){
                        
                            this.setState({selectedModTime:time})
                            this.props.handleModApptTime(time)
                        }else if(restrictedHour===selectedhour){
                            if(SelectedMiniute>restrictedMinute){
                               
                                this.setState({selectedModTime:time})
                                this.props.handleModApptTime(time)
                            }else{
                                this.setState({isTimeModalVisible:true,
                                    timeModalText:"Please select the time properly.Selected time is in past."}) 
                            }

                        }else{
                            this.setState({isTimeModalVisible:true,
                                timeModalText:"Please select the time properly.Selected time is in past."}) 
                        }
                        
                    }else{
                        this.setState({isTimeModalVisible:true,
                            timeModalText:"Please select the time in 15 min Range"})
                    }
                
            }else{
                this.setState({isTimeModalVisible:true,
                timeModalText:"Please Select Hours Between 9 AM to 18 PM"})
            }
            
    }

    hangleTimeModalOK = () => {
        this.setState({isTimeModalVisible:false})
    }

    render() {
        return (
            <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            
                <TimePicker value={this.state.selectedModTime} 
                placeholder="Select Time"
                onChange={this.handleModTimeChange}
                className = "modApptTime"
                ampm = {false} 
                minutesStep = {15}
                disablePast = {true}
                style={{height:this.props.timePickerSize}}
                clearable = {true}/>
                            
            </MuiPickersUtilsProvider>
            <Modal title="Alert" 
            visible={this.state.isTimeModalVisible} 
            destroyOnClose={true}
            // onOk={this.handleOk} 
            footer = {false}
            onCancel={this.handleCancel}
            >
                <p style={{color:"red"}}>{this.state.timeModalText}</p>
                <Button type="danger" onClick = {this.hangleTimeModalOK}>Ok</Button>
                
            </Modal>
            </div>
            );
    }
}

export default MTimePicker;