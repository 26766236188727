import React, { Component } from 'react';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    NavbarText
} from 'reactstrap';
import { Link } from 'react-router-dom';

import logo from "../assets/images/sgm_analytics.png";
import "../css/topNav.css";
import config from "../config.json";





class Topnav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        } 
    }

    // window.onbeforeunload = function() { return "Your work will be lost."; };

    handleLogout = () => {
        this.props.handleEndCall();
        localStorage.removeItem('loginStatus')
    }

    handleappointmentEndcall = () => {
        this.props.handleEndCall();
    }
    handleIviteLink = () =>{
        this.props.handleIviteLink();
    }
    handleMeetingInfo = () => {
        this.props.handleMeetingInfo();
    }
    handleListOfAppt = () => {
        this.props.handleListOfAppt();
    }

    toggle = () => this.setState({ isOpen: !this.state.isOpen });

    render() {
      

        let userType = localStorage.getItem("userType");
 
        return (
            <div style={{}} className = "topNavMainContainer">
                <div>
                    <Navbar color="light" light expand="md">
                        <NavbarBrand >
                            <img
                                // width="100%"
                                src={logo}
                                alt="logo"
                                className="sgmLogo"
                            />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        {/* <h5>Welcome <span className="userTitle">{this.state.userName}</span></h5> */}
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="me-auto" navbar>
                                {userType !== "6" ?
                                <NavItem style={{display:this.props.display}} className = "navItemsLink">
                                    <Link to="#" onClick = {this.handleMeetingInfo}>Meeting Info</Link>
                                    
                                </NavItem>:null}
                                {userType !== "6" ? userType !== "5" ?
                                <NavItem style={{display:this.props.display}} className = "navItemsLink">
                                <Link to="#" onClick = {this.handleIviteLink}>Meeting Invite Link</Link>
                                    
                                </NavItem>:null:null}
                                {/* {userType !== "6" ?
                                <NavItem style={{display:this.props.display}} className = "navItemsLink">
                                    <Link to="#" onClick = {this.handleListOfAppt}>List of appointments</Link>
                                    
                                </NavItem>:null} */}
                                {userType === "1" ?
                                <NavItem style={{display:this.props.admin}} className = "navItemsLink">
                                    <Link to="/Admin" >Admin</Link>
                                    
                                </NavItem>:null}
                                {userType !== "6" ?
                                <NavItem style={{display:this.props.appointments}} className = "navItemsLink">
                                    <Link to="/Appointment" >Appointment</Link>
                                    
                                </NavItem>:null}
                                {/* <NavItem style={{display:this.props.display}} className = "navItemsLink">
                                    <Link to="#" onClick = {this.handleappointmentEndcall}>List of appointments</Link>
                                    
                                </NavItem>:null} */}
                               
                                  
                            </Nav>
                            {userType !== "6" ?
                            <Link to = "/"  className= "navlinkOptions" onClick = {this.handleLogout}>Logout</Link>:null}
                        </Collapse>
                    </Navbar>
                </div>

            </div>
        );
    }
}

export default Topnav;