import React, { Component } from 'react';
import {
    Statistic, Row, Col, Button, Modal, Tabs,
    Select, Input, Form, Checkbox, DatePicker, Space, Popconfirm, message, Result, AutoComplete,
    Popover
} from 'antd';
// import { DeleteFilled } from '@ant-design/icons';
import { DeleteFilled, EditFilled, AudioOutlined, InfoCircleFilled } from "@ant-design/icons";
import { connect } from 'react-redux';
import config from '../config.json';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
// import _ from "lodash";
let countryCodes = require('country-codes-list')
import _, { first } from "lodash";
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Topnav from "../components/topNav";
import MTimePicker from "../components/timePicker";
import ApptSearch from "../components/searchAppt";
import ApptExcel from "../components/apptExcel";
import MeetExcel from "../components/meetExcel";

import ClentTimePicker from "../components/timePicker1";

import "../css/appointment.css";
import {
    UserAppointmentServices, patientApptdetailSearch, createAppointmentService
    , modifyAppointmentService, findAppointmentService
} from "../services/appointmentService";
import { UserMeetingtServices, createMeetingService } from "../services/meetingServices"
import { SessionDetailsServices } from "../services/sessionDetailsService";
import { InvitationLinkServices } from "../services/invitationLinkService";
import { CreateServices, UserListServices, UserDeleteServices, UsermodServices } from "../services/adminServices";

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { applyMiddleware } from 'redux';

import { CSVLink } from "react-csv";

import deleteIcon from "../assets/images/trash.png"
import Chips from 'react-email-chips';
import MeetingDetails from "../components/meeting"

const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;

const { RangePicker } = DatePicker;

const pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 11,
        span: 13,
    },
};




class Appointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'top',
            appointments: [],
            numAppts: "",
            userName: "",
            userType: "",
            cancelAppt: [],
            isInvitationModalVisible: false,
            isModApptModalVisible: false,
            urlLink: "",
            autoCompleteDocResult: [],
            autoCompleteConsResult: [],
            contryPhoneCodes: [],
            typeConsultaton: true,
            consultationDate: "",
            consultationTime: "",
            phoneNo: "",
            selectedCountryCode: "+65",
            patientName: "",
            patientEmail: "",
            isUserAvailable: false,
            userDetailsviaPhone: [],
            availableDocList: [],
            availableConsList: [],
            isPhonenoUpdated: false,
            isCreatePatientdisabled: false,
            doctorsData: [],
            consData: [],
            apptModModalDetials: {},
            modApptDocId: "",
            modApptConId: "",
            modApptDate: null,
            modApptTime: null,
            ErrorInJoinCall: false,
            selectedModTime: "",
            searchText: "",
            totalAppt: null,
            searchCriteriaInp: "",
            searchEndDate: null,
            searchStartDate: new Date(),
            SearchphnoChange: "Phone",
            SearchTypeChange: "Patient",
            searchApptResults: [],
            searchApptResultsdup: [],
            isDurationVisible: true,
            activeKey: "1",
            popOverContent: false,
            emailParticipants: [],
            isSelectedModTimeValid: true,
            listOfMeetings: [],
            activeMeet: [],
            expiredMeet: [],
            listofMeetingdup: [],


            options: ["Anurag Srivastav", "Maheedhar Bose Juvva", "Vijay G", "Gary Yang",
                "Jack Guo", "Tim Gao", "Dr S K Tan", "Dr Ian Flemming", "Chester Wan", "Amflower Wong"]


        }
    }

    componentDidMount = () => {
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
            history.go(1);

        };
        const loginStatus = localStorage.getItem('loginStatus');
        this.handleUserAppointments();
        this.handleUserMeetings();

        // let totalTabs = document.getElementsByClassName("ant-tabs-tab")
    
        // totalTabs.setAttribute('id', "idddd" )

        let subscriberLength = [...document.querySelectorAll('.ant-tabs-tab')]
     
        // if(subscriberLength.length !==0){
        //     // $("p:first").addClass("intro");
        //     subscriberLength.map((x,index )=>{
        //         // if(index === subscriberLength.length-1){
        //             x.appendChild(button) 
        //         // }
        //     })



    }

    handleUserMeetings = () => {
        const userId = localStorage.getItem('userId');
        let MeetObj = {
            userId: userId.toString(),
            duration: "15"
        }
        UserMeetingtServices(MeetObj).then((response) => {
        
            // this.setState({})
            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
                let result = response.data;
                const expired = result.appointments.filter(appt => appt.status !== "Active");
                const active = result.appointments.filter(appt => appt.status === "Active");
                this.setState({
                    activeMeet: active,
                    expiredMeet: expired, listOfMeetings: response.data, listofMeetingdup: response.data
                })
           

            }

        })
    }

    handleUserAppointments = () => {
        const myCountryCodesObject = countryCodes.customList('countryCode', `+{countryCallingCode}`)
        let contryPhoneCodes = Object.values(myCountryCodesObject)
        const userId = localStorage.getItem('userId');
        const userType = localStorage.getItem('userType');
        let ApptDetails = {
            userId: userId.toString(),
            duration: "7"
        }
       

        UserAppointmentServices(ApptDetails).then((response) => {
            

            if (response.status === 200) {
                let result = response.data;
                const expired = result.appointments.filter(appt => appt.status !== "Active");
                const active = result.appointments.filter(appt => appt.status === "Active");
                localStorage.setItem("activeAppt", JSON.stringify(active))
                localStorage.setItem("userName", result.userName)
             
                this.setState({
                    contryPhoneCodes,
                    totalAppt: result.appointments,
                    appointments: active,
                    cancelAppt: expired,
                    numAppts: result.numAppts.toString(),
                    userName: result.userName,
                    userType: userType.toString(),
                })
            } else
                if (response.status === 400) {
                    Modal.error({
                        title: "Error",
                        content: response.data,
                    });
                }

        })

    }
    cuation = () => {
       
    }


    handleDeleteConfirm = (index, appt) => {
        const userId = localStorage.getItem('userId');
      
        let modApptObj = {
            apptID: appt.appointmentId,
            apptStatus: "Expired",
            logUser: userId,
        }
        
        modifyAppointmentService(modApptObj).then((response) => {
            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
            this.handleUserAppointments();
            }
        })
        // , (res) => {
        //     message.error('Error in delete Appointment !');
        // }

    }

    handleDeletecancel = (e) => {
     
        message.error('Rejected to delete !');
    }

    handleJoinCall = (index, appt) => {
       
       
        localStorage.setItem('appt', JSON.stringify(appt))
        const userId = localStorage.getItem('userId');
        let sessionDestails = {
            userId: userId,
            sessionId: appt.sessionId,
            userType: this.state.userType,
            sessionType: "Regular"
        }
        SessionDetailsServices(sessionDestails).then((response) => {

            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
            
            let result = response.data
            config.apiKey = result.apiKey;
            config.sessionId = appt.sessionId;
            config.token = result.token;
            config.userName = this.state.userName;
            localStorage.setItem('apiKey', result.apiKey)
            localStorage.setItem('sessionId', appt.sessionId)
            localStorage.setItem('token', result.token)
           
            if (config.apiKey !== "" && config.sessionId !== "" && config.token !== "") {
                this.props.history.push("VideoCalling");
            } else {
                this.setState({ ErrorInJoinCall: true })
            }
        }

        })

    }
    handleJoinCallMeet = (index, appt) => {
     
       
        localStorage.setItem('appt', JSON.stringify(appt))
        const userId = localStorage.getItem('userId');
        let sessionDestails = {
            userId: userId,
            sessionId: appt.sessionId,
            userType: this.state.userType,
            sessionType: "RegularMeeting"
        }
    
        SessionDetailsServices(sessionDestails).then((response) => {

            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
            
            let result = response.data
            config.apiKey = result.apiKey;
            config.sessionId = appt.sessionId;
            config.token = result.token;
            config.userName = this.state.userName;
            localStorage.setItem('apiKey', result.apiKey)
            localStorage.setItem('sessionId', appt.sessionId)
            localStorage.setItem('token', result.token)
           
            if (config.apiKey !== "" && config.sessionId !== "" && config.token !== "") {
                this.props.history.push("VideoCalling");
            } else {
                this.setState({ ErrorInJoinCall: true })
            }
        }

        })

    }
    handleWeekChange = (value) => {
  
        const userId = localStorage.getItem('userId');

        let ApptDetails = {
            userId: userId.toString(),
            duration: value
        }
      

        UserMeetingtServices(ApptDetails).then((response) => {
            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
            
         
            // this.setState({})
            let result = response.data; ``
            const expired = result.appointments.filter(appt => appt.status !== "Active");
            const active = result.appointments.filter(appt => appt.status === "Active");
            this.setState({
                activeMeet: active,
                expiredMeet: expired, listOfMeetings: response.data, listofMeetingdup: response.data
            })
                }
        })

        UserAppointmentServices(ApptDetails).then((response) => {
            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
           
                let result = response.data;
                const expired = result.appointments.filter(appt => appt.status !== "Active");
                const active = result.appointments.filter(appt => appt.status === "Active");
                localStorage.setItem("activeAppt", JSON.stringify(active))

               
                this.setState({
                    totalAppt: result.appointments,
                    appointments: active,
                    cancelAppt: expired,
                })

            }


        })




    }

    onFinish = (values) => {
    


        let filteredDocs = _.filter(
            this.state.doctorsData,
            (item) => _.includes(item.userName.toLowerCase(), values.doctorName.toLowerCase()) === true
        );
        let filteredCons = _.filter(
            this.state.consData,
            (item) => _.includes(item.userName.toLowerCase(), values.consName.toLowerCase()) === true
        );


        const userId = localStorage.getItem('userId');


        let appdetail = {
            criteria: "Phone",
            userId: "",
            userName: "",
            userType: "",
            phone: `${this.state.selectedCountryCode}${this.state.phoneNo}`,
            email: ""
        }
       

        patientApptdetailSearch(appdetail).then((response) => {

            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
            let details = response.data.usersList[0]
            let apptDate = new Date(new Date(values.date).toDateString() + " " +
                this.state.selectedModTime).toGMTString().slice(0, 16)
            let apptTime = new Date(new Date(values.date).toDateString() + " " +
                this.state.selectedModTime).toGMTString().slice(17, 22)


            let apptobj =
            {
                apptType: values.apptType,
                date: moment(apptDate).format("YYYY-MM-DD"),
                time: apptTime,
                drId: filteredDocs[0].userId.toString(),
                ptId: details.userId.toString(),
                consId: filteredCons[0].userId.toString(),
                purpose: values.purpose,
                origApptDateTime: moment(moment(values.date).format("YYYY-MM-DD") + " " +
                    this.state.selectedModTime).format(),
                logUser: userId,
                reqType: "Create"

            }



           


            createAppointmentService(apptobj).then((response) => {
                if (response.status === 400) {
                    Modal.error({
                        title: "Error",
                        content: response.data,
                    });
                } else {
               
                message.success('Successfully Appointment Created !');
                this.handleUserAppointments();
                }

            })
            // , (response) => {
            //     message.error('Error in Appointment Creation !');
            //     this.handleUserAppointments();
            // }
        }

        })
        // , (res) => {

        // }


    };

    onFinishFailed = (errorInfo) => {
       
    };
    onFinishMeetingFailed = (errorInfo) => {
        
        this.handleMeetingValidations()
    }
    handleMeetingValidations = () => {

        if (this.state.selectedModTime !== "") {
            this.setState({ isSelectedModTimeValid: true })

        } else {
            this.setState({ isSelectedModTimeValid: false })
        }
    }
    onMeetingFinish = (values) => {
        
        this.handleMeetingValidations()
        // emailParticipants
        const userId = localStorage.getItem('userId');
        let meetDate = new Date(new Date(values.date).toDateString() + " " +
            this.state.selectedModTime).toGMTString().slice(0, 16)
        let meetTime = new Date(new Date(values.date).toDateString() + " " +
            this.state.selectedModTime).toGMTString().slice(17, 22)

        let partarray = []
        for (let i = 0; i < this.state.emailParticipants.length; i++) {
            partarray.push(this.state.emailParticipants[i].email);
        }
        if (this.state.isSelectedModTimeValid === true) {
            let meetingObj = {
                organizer: userId,
                participants: partarray,
                date: moment(meetDate).format("YYYY-MM-DD"),
                time: meetTime,
                orgMeetDateTime: moment(moment(values.date).format("YYYY-MM-DD") + " " +
                    this.state.selectedModTime).format(),
                logUser: userId,
                reqType: "Create",
                purpose: values.purpose,
                apptType: "1",
                duration: values.Duration
            }
           
            createMeetingService(meetingObj).then((response) => {
                if (response.status === 400) {
                    Modal.error({
                        title: "Error",
                        content: response.data,
                    });
                } else {
           
                message.success('Successfully Meeting Created !');
                this.handleUserMeetings();
                }

            })
            // , (response) => {
            //     message.error('Error in Meeting Creation !');
            //     // this.handleUserMeetings();
            // }
        }
    }
    onChange = (value, dateString) => {
       
    }

    onOk = (value) => {
     
    }
    handleInviteLink = (appt, index) => {

       
        let appointmentId = appt.appointmentId
       
        let urlText = `http://izoom.ids-group.co/#/Adhoc?appointmentId=${appointmentId}`
        this.setState({ isInvitationModalVisible: true, urlLink: urlText })
    }

    handleInvitationModalOk = () => {
        this.setState({ isInvitationModalVisible: false });
    }
    handleInvitationModalCancel = () => {
        this.setState({ isInvitationModalVisible: false });
    }

    handleModApptModalOk = () => {
        this.setState({ isModApptModalVisible: false });
    }
    handleModApptModalCancel = () => {
        this.setState({ isModApptModalVisible: false });
    }

    handleEditLink = (appt, index) => {
        
        this.onDocConDetails();

        this.setState({ isModApptModalVisible: true, apptModModalDetials: appt })
    }
    handleCopyText = () => {

   
    }

    onSelect = (data) => {
       
    };

    onChangedate = (date) => {

      
    };
    onChangeTime = (time) => {
      
    }


    handleDoctorNameChange = (value) => {
        if (!value) {
            this.setState({ autoCompleteDocResult: [] });
        } else {
           
            let filteredDocs = _.filter(
                this.state.availableDocList,
                (item) => _.includes(item.toLowerCase(), value.toLowerCase()) === true
            );
            this.setState({ autoCompleteDocResult: filteredDocs });
        }
    };
    handleConsNameChange = (value) => {
        if (!value) {
            this.setState({ autoCompleteConsResult: [] });
        } else {
            
            let filteredCons = _.filter(
                this.state.availableConsList,
                (item) => _.includes(item.toLowerCase(), value.toLowerCase()) === true
            );
            this.setState({ autoCompleteConsResult: filteredCons });
        }
    }

    onAppointmentTypeChange = (value) => {
      
        if (value === "1") {
            this.setState({ typeConsultaton: true })
        } else {
            this.setState({ typeConsultaton: false })
        }
    }


    getDisabledHours = () => {
        var hours = [];
        for (var i = 1; i <= moment().hour(); i++) {
            hours.push(i);
        }
       
        return hours;
    }
    getDisabledMinutes = (selectedHour) => {
        var minutes = [];
        if (selectedHour === moment().hour()) {
            for (var i = 0; i < moment().minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }
    disabledDate = current => {
        return moment(current).day() === 0 || current <= moment().startOf('day');
    }
    handleinitaildateValue = () => {
        // let value =  moment().add(1, 'days');
        let value = moment();
        
        return moment(value);
    }
    handlePhoneNo = (e) => {
       
        this.setState({ phoneNo: e.target.value })
    }
    onSelectCountryCode = (value) => {
       
        this.setState({ selectedCountryCode: value })
    }

    onDocConDetails = () => {

        let doctorDetailsObj = {
            criteria: "UserType",
            userId: "",
            userName: "",
            userType: "2",
            phone: "",
            email: ""
        }
        let consDetailsObj = {
            criteria: "UserType",
            userId: "",
            userName: "",
            userType: "3",
            phone: "",
            email: ""
        }

        // let doctorDetails = []
        // let consDetails = []
        patientApptdetailSearch(doctorDetailsObj).then((response) => {
            // doctorDetails = response.data.usersList
            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
            let docdetail = response.data.usersList;

            
            let docNameArray = [];

            for (let i = 0; i < docdetail.length; i++) {
                docNameArray.push(docdetail[i].userName)
            }

           
            this.setState({ availableDocList: docNameArray, doctorsData: docdetail, })
        }
        })
        // , (response) => {


        // }
        patientApptdetailSearch(consDetailsObj).then((response) => {
            // consDetails = response.data.usersList
            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
            let consDetails = response.data.usersList;
            let consNameArray = [];
            for (let i = 0; i < consDetails.length; i++) {
                consNameArray.push(consDetails[i].userName)
            }
            this.setState({ availableConsList: consNameArray, consData: consDetails })
        }
            // this.setState({availableConsList:response.data.usersList})
        })
        // , (response) => {


        // }
        // this.setState({ patientName: details.userName, patientEmail: details.email,isUserAvailable:true })




    }
    onBlurPhoneNo = () => {

        let appdetail = {
            criteria: "Phone",
            userId: "",
            userName: "",
            userType: "",
            phone: `${this.state.selectedCountryCode}${this.state.phoneNo}`,
            email: ""
        }
     

        patientApptdetailSearch(appdetail).then((response) => {
            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
        
            let details = response.data.usersList
            this.setState({ isPhonenoUpdated: true })

            this.onDocConDetails();
            if (details.length !== 0) {

                this.setState({
                    isUserAvailable: true, userDetailsviaPhone: details,
                })

            } else {
                this.setState({ isUserAvailable: false })
            }
        }

        })
        // , (response) => {


        // }



    }

    handlePatientEmail = (e) => {
       
        this.setState({ patientEmail: e.target.value })
    }
    handlePatientName = (e) => {
       
        this.setState({ patientName: e.target.value })
    }
    hadleCreatePatient = () => {
        const logUserId = localStorage.getItem('userId');
        let userDetails = {
            userName: this.state.patientName,
            email: this.state.patientEmail,
            phone: `${this.state.selectedCountryCode}${this.state.phoneNo}`,
            userType: "5",
            logUser: logUserId
        }
       

        if (this.state.patientName !== "" && this.state.patientEmail !== "") {
            CreateServices(userDetails).then((response) => {
                if (response.status === 400) {
                    Modal.error({
                        title: "Error",
                        content: response.data,
                    });
                } else {
              
                if (response.data !== undefined) {
                    message.success('Patient created successfully!');
                    this.setState({ isCreatePatientdisabled: true })
                } else {
                    message.error('Error in patient creation!');
                }
            }

                // let userList = {
                //   criteria: "All",
                //   userId: "",
                //   userName: "",
                //   userType: "",
                //   phone: "",
                //   email: "",
                // }
            })
            // , (response) => {
            //     message.error('Error in patient creation!');
            // }
        } else {
            message.error('Please fill patient name and email!');
        }


    }

    handleModApptDocChange = (value) => {
      
        this.setState({ modApptDocId: value })

    }
    handleModApptConChange = (value) => {
        
        this.setState({ modApptConId: value })
    }

    modApptDateChange = (date) => {
       
        this.setState({ modApptDate: date })
    }
    modApptTimeChange = (time) => {
        
        this.setState({ modApptTime: time })
    }

    handleModApptSubmit = () => {

        
        const userId = localStorage.getItem('userId');

        if (this.state.modApptDocId !== "" && this.state.modApptConId !== "" &&
            this.state.modApptDate !== null && this.state.selectedModTime !== null) {


            let apptDate = new Date(new Date(this.state.modApptDate).toDateString() + " " +
                this.state.selectedModTime).toGMTString().slice(0, 16)
            let apptTime = new Date(new Date(this.state.modApptDate).toDateString() + " " +
                this.state.selectedModTime).toGMTString().slice(17, 22)



            let apptobj =
            {
                apptType: this.state.apptModModalDetials.appointmentType,
                date: moment(apptDate).format("YYYY-MM-DD"),
                time: apptTime,
                drId: this.state.modApptDocId.toString(),
                ptId: "",
                apptID: this.state.apptModModalDetials.appointmentId,
                consId: this.state.modApptConId.toString(),
                purpose: this.state.apptModModalDetials.data,

                origApptDateTime: moment(moment(apptDate).format("YYYY-MM-DD") + " " +
                    this.state.selectedModTime).format(),
                logUser: userId,
                reqType: "Modify"

            }

          

            createAppointmentService(apptobj).then((response) => {
                if (response.status === 400) {
                    Modal.error({
                        title: "Error",
                        content: response.data,
                    });
                } else {
                
                message.success('Appointment Created Successfully !');
                this.handleUserAppointments();
                this.setState({ isModApptModalVisible: false })
                }

            })
            // , (response) => {
            //     message.error('Error in Appointment Creation !');
            //     this.handleUserAppointments();
            //     this.setState({ isModApptModalVisible: false })
            // }


        } else {
            message.error('Please fill all the details!');
        }

    }

    handleapptTimeGmt = (time) => {

      
        let appttime = moment(time).format('MMMM Do YYYY, h:mm:ss a')
        return appttime;

    }


    handleErrorInJoinCallModalOk = () => {
        this.setState({ ErrorInJoinCall: false })
    }
    handleErrorInJoinCallModalCancel = () => {
        this.setState({ ErrorInJoinCall: false })
    }

    // handleModTimeChange = (time) => {
    //    
    //     this.setState({selectedModTime:time})
    // }

    handleModApptTime = (time) => {
     
        this.setState({ selectedModTime: time }, () => this.handleMeetingValidations())


    }

    onSearch = (value) => {
      
    }

    handleSearchResult = (text) => {
        
        let searchText = text;

        if (searchText === "") {
            const expired = this.state.totalAppt.filter(appt => appt.status !== "Active");
            const active = this.state.totalAppt.filter(appt => appt.status === "Active");
            const expiredMeet = this.state.listOfMeetings.appointments.filter(appt => appt.status !== "Active");
            const activeMeet = this.state.listOfMeetings.appointments.filter(appt => appt.status === "Active");
            this.setState({
                appointments: active,
                cancelAppt: expired,
                activeMeet: activeMeet,
                expiredMeet: expiredMeet,
                searchApptResults: this.state.searchApptResultsdup,
                listOfMeetings: this.state.listofMeetingdup
            })
        } else {
            this.setState({ searchText: searchText.toLowerCase() })

        }
    }

    handleSubSearchResults = (value) => {

        let apptType = value === "active" ? this.state.appointments :
            value === "expire" ? this.state.cancelAppt : this.state.searchApptResults;
        
        let filteredAppt1 = _.filter(
            apptType,
            (appt) => _.includes(appt.drName.toLowerCase(), this.state.searchText) === true
        );
        let filteredAppt2 = _.filter(
            apptType,
            (appt) => _.includes(appt.ptName.toLowerCase(), this.state.searchText) === true
        );
        let filteredAppt3 = _.filter(
            apptType,
            (appt) => _.includes(appt.consName.toLowerCase(), this.state.searchText) === true
        );
        let filteredAppt4 = _.filter(
            apptType,
            (appt) => _.includes(appt.createdBy.toLowerCase(), this.state.searchText) === true
        );
        let filteredAppt5 = _.filter(
            apptType,
            (appt) => _.includes(appt.modBy.toLowerCase(), this.state.searchText) === true
        );
        let filteredAppt6 = _.filter(
            apptType,
            (appt) => _.includes(appt.status.toLowerCase(), this.state.searchText) === true
        );

        let filteredAppt = [...filteredAppt1, ...filteredAppt2, ...filteredAppt3,
        ...filteredAppt4, ...filteredAppt5, ...filteredAppt6]

       

        return filteredAppt;

    }

    handleMeetSearchResults = (value) => {
        let apptType = value === "active" ? this.state.activeMeet :
            value === "expire" ? this.state.expiredMeet : this.state.listOfMeetings.appointments;
       
        let filteredAppt1 = _.filter(
            apptType,
            (appt) => _.includes(appt.Organizer.toLowerCase(), this.state.searchText) === true
        );
        let filteredAppt2 = _.filter(
            apptType,
            (appt) => _.includes(appt.createdBy.toLowerCase(), this.state.searchText) === true
        );
        let filteredAppt3 = _.filter(
            apptType,
            (appt) => _.includes(appt.modBy.toLowerCase(), this.state.searchText) === true
        );
        let filteredAppt4 = _.filter(
            apptType,
            (appt) => _.includes(appt.particpants.toLowerCase(), this.state.searchText) === true
        );
        let filteredAppt5 = _.filter(
            apptType,
            (appt) => _.includes(appt.data.toLowerCase(), this.state.searchText) === true
        );

        let filteredAppt = [...filteredAppt1, ...filteredAppt2, ...filteredAppt3,
        ...filteredAppt4, ...filteredAppt5]

        

        return filteredAppt;
    }

    handleFinalSearchResult = () => {


        let filteredAppt = this.handleSubSearchResults()

        const expired = filteredAppt.filter(appt => appt.status !== "Active");
        const active = filteredAppt.filter(appt => appt.status === "Active");

       
        this.setState({
            appointments: active,
            cancelAppt: expired,
        })
    }

    handleFinalActiveSearchResult = () => {
        let filteredAppt = this.handleSubSearchResults("active")
        const active = filteredAppt.filter(appt => appt.status === "Active");
        this.setState({
            appointments: active,

        })
    }
    handleFinalexpireSearchResult = () => {
        let filteredAppt = this.handleSubSearchResults("expired")
        const expired = filteredAppt.filter(appt => appt.status !== "Active");
        this.setState({

            cancelAppt: expired,
        })
    }

    handleExpireMeetSearchResult = () => {
        let filteredAppt = this.handleMeetSearchResults("expired")
        const expired = filteredAppt.filter(appt => appt.status !== "Active");
        this.setState({

            expiredMeet: expired,
        })
    }

    handleActiveMeetSearchResult = () => {
        let filteredAppt = this.handleMeetSearchResults("active")
        const active = filteredAppt.filter(appt => appt.status === "Active");
        this.setState({
            activeMeet: active,

        })

    }

    handleFinalApptSearchResult = () => {
        let filteredAppt = this.handleSubSearchResults("search")
        // const expired = filteredAppt.filter(appt => appt.status !== "Active");
        this.setState({

            searchApptResults: filteredAppt,
        })
    }

    handleSearchTypeChange = (value) => {
       
        this.setState({ SearchTypeChange: value })
    }

    handleSearchphnoChange = (value) => {
        
        this.setState({ SearchphnoChange: value })
    }
    handleStartChange = (date) => {
        this.setState({ searchStartDate: date })
    }
    handleEndChange = (date) => {
        this.setState({ searchEndDate: date })

    }
    handleSearchCriteriaInp = (e) => {
        let value = e.target.value;
        this.setState({ searchCriteriaInp: value })
    }
    handleCsSearch = () => {
        let searchObj = {
            identity: this.state.SearchTypeChange,
            parameter: this.state.SearchphnoChange,
            value: this.state.searchCriteriaInp,
            startDate: moment(this.state.searchStartDate).format("YYYY-MM-DD"),
            endDate: this.state.searchEndDate === null ? "" : moment(this.state.searchEndDate).format("YYYY-MM-DD")
        }

        

        // findAppointmentService
        findAppointmentService(searchObj).then((response) => {

            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
           
            // message.success('Appointment Created Successfully !');
            // this.handleUserAppointments();
            this.setState({
                searchApptResults: response.data.appointments,

                searchApptResultsdup: response.data.appointments
            })
        }

        })
        // , (response) => {
        //     message.error('Oops something went wrong!');
        //     // this.handleUserAppointments();
        //     // this.setState({ isModApptModalVisible: false })
        // }
    }


    handleDuarationVisibletrue = (activeKey) => {
       
        if (activeKey === "4" || activeKey === "3" || activeKey === "7") {
            this.setState({ isDurationVisible: false })
        } else {
            this.setState({ isDurationVisible: true })
        }
        this.setState({ activeKey });

    }

    content = (appt) => {

      


        return (
            this.state.popUserDetails &&
            <div>
                <p className="modUserDetailsModal">Name : <span>{appt.ptName}</span></p>
                <p className="modUserDetailsModal">Phone No : <span>{this.state.popUserDetails[0].mobile}</span></p>
                <p className="modUserDetailsModal">Email : <span>{this.state.popUserDetails[0].email}</span></p>
            </div>)




    }
    handlePopClick = (appt) => {
     
        let userList = {
            criteria: "UserId",
            userId: appt.ptID.toString(),
            userName: "",
            userType: "",
            phone: "",
            email: ""
        }
      
        UserListServices(userList).then((response) => {
            

            if (response.status === 400) {
                Modal.error({
                    title: "Error",
                    content: response.data,
                });
            } else {
                
                let userListDetails = response.data.usersList;
                this.setState({ popUserDetails: userListDetails })
            }

        })
        // , (response) => {
        // }
    }
    render() {
        const DoctorOptions = this.state.autoCompleteDocResult.map(doctorName => ({
            label: doctorName,
            value: doctorName,
        }));
        const ConsultantOptions = this.state.autoCompleteConsResult.map(consName => ({
            label: consName,
            value: consName,
        }));

        const headers = [
            { label: "Patient's Name", key: 'ptName' },
            { label: "Doctor's Name", key: 'drName' },
            { label: "Consultant's Name", key: 'consName' },
            { label: "Confirmed Appointment Time", key: 'appointmentDateTime' },
            { label: "Created By", key: 'createdBy' },
            { label: "Created Time", key: 'createTime' },
            { label: "Modified By", key: 'modBy' },
            { label: "Modified Time", key: 'modTime' },
            { label: "Purpose", key: 'data' },

        ];


        return (
            <div style={{ height: "100%" }}>
                <Topnav display="none" admin="flex" appointments="none"
                   />
                <div style={{ height: 'auto' }}>
                    <h5 className="userTitleMain">Welcome <span className="userTitle">{this.state.userName}</span></h5>
                    <div className="appointmentFilterContainer">

                        {this.state.isDurationVisible === true ?
                            <Select defaultValue="Next 7 Days"
                                className="appointmentSelectFilter"
                                style={{ width: '10%' }}
                                size="large"
                                onChange={this.handleWeekChange}>
                                <Option value="-30">Past 30 Days</Option>
                                <Option value="-15">Past 15 Days</Option>
                                <Option value="-7">Past 7 Days</Option>
                                <Option value="7">Next 7 Days</Option>
                                <Option value="15">Next 15 Days</Option>
                                <Option value="30">Next 30 Days</Option>
                            </Select> : null}
                    </div>
                    <Tabs defaultActiveKey="1"
                        activeKey={this.state.activeKey}
                        onChange={this.handleDuarationVisibletrue}
                        tabPosition={isMobile ? "top" : "left"}
                        className={isMobile ? "resappointmentTabContainer" : "appointmentTabContainer"} >

                        <TabPane tab="Appointment" key="1"   >
                            <h6 style={{ textAlign: "right" }}>No of appointments : {this.state.appointments.length}</h6>
                            {this.state.appointments.length !== 0 ?
                                <Row gutter={16} className="Intsearchmain">
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="searchMain">
                                        <ApptSearch
                                            handleSearchResult={this.handleSearchResult}
                                            handleFinalSearchResult={this.handleFinalActiveSearchResult} />
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="excelMain">
                                        <ApptExcel data={this.state.appointments} />
                                    </Col>
                                </Row> : null}
                            <Row gutter={16} className="appointmentMain">
                                {this.state.appointments.length !== 0 ?
                                    (this.state.appointments.map((appt, index) =>
                                        <Col xs={24} sm={24} md={20} lg={20} xl={20}
                                            className={this.state.userType !== "5" ? "appointmentDoctor" : "appointmentPatient"}
                                            key={appt.appointmentId}>
                                            <Row gutter={16} className="apptMainRow">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Statistic title="Patient's Name"
                                                        value={appt.ptName}
                                                        prefix={<Popover content={this.content(appt)}
                                                            title="Details" trigger="click" onClick={() => this.handlePopClick(appt)}>
                                                            <InfoCircleFilled className="InfoApptName" />
                                                        </Popover>}
                                                        valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                {appt.appointmentType !== 2 ?
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <Statistic title=
                                                            "Doctor's Name"
                                                            value={appt.drName} valueStyle={{ fontSize: '16px' }} />
                                                    </Col> : null}
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6} className="appointmentJoinContainer">
                                                    <Statistic title="Confirmed appointment time"
                                                        // value={moment(appt.appointmentDateTime).format('MMMM Do YYYY, h:mm:ss a')} 
                                                        value={this.handleapptTimeGmt(appt.appointmentDateTime)}
                                                        valueStyle={{ fontSize: '16px' }} />
                                                </Col>

                                                {this.state.userType !== "5" ?

                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                                        <Statistic title="Created By" value={appt.createdBy} valueStyle={{ fontSize: '12px' }} />
                                                    </Col> : null}

                                                {this.state.userType !== "5" ?
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                                        <Statistic title="Created Time" value={moment(appt.createTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '12px' }} />
                                                    </Col> : null}


                                                {this.state.userType !== "5" ?
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                                        {appt.modBy !== "Unknown" ?
                                                            <Statistic title="Last Modified By" value={appt.modBy} valueStyle={{ fontSize: '12px' }} /> : null}
                                                    </Col> : null}

                                                {this.state.userType !== "5" ?
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                                        {appt.modTime !== null ?
                                                            <Statistic title="Last Modification Time" value={moment(appt.modTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '12px' }} /> : null}
                                                    </Col>

                                                    : null}


                                                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                                    <Statistic title="Purpose" value={appt.data} valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                    <Button
                                                        className="appointmentJoinDoctor"
                                                        type="primary"
                                                        size="middle"
                                                        // disabled
                                                        onClick={() => this.handleJoinCall(index, appt)}
                                                    >Join call</Button>
                                                </Col>
                                                {this.state.userType !== "5" ?
                                                    <Button type="danger" className="apptEditButton"
                                                        onClick={() => this.handleEditLink(appt, index)}>
                                                        <EditFilled style={{ color: "#000000", fontSize: "20px" }} />
                                                    </Button> : null}
                                                {this.state.userType !== "5" ?
                                                    <Popconfirm
                                                        title="Are you sure to delete this task?"
                                                        onConfirm={() => this.handleDeleteConfirm(index, appt)}
                                                        onCancel={this.handleDeletecancel}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button type="danger" className="apptCancelButton">
                                                            <img src={deleteIcon} alt="delete Icon" className="deleteButtonImg" />
                                                        </Button>

                                                    </Popconfirm> : null}
                                                {this.state.userType !== "5" ?
                                                    <Button type="danger" className="apptCancelButton1"
                                                        onClick={() => this.handleInviteLink(appt, index)}>
                                                        Invite
                                                    </Button> : null}

                                            </Row>
                                        </Col>)) : null}
                            </Row>
                        </TabPane>
                        <TabPane tab="Expired Appointment" key="2" >
                            <h6 style={{ textAlign: "right" }}>No of appointments : {this.state.cancelAppt.length}</h6>
                            {this.state.cancelAppt.length !== 0 ?


                                <Row gutter={16} className="Intsearchmain">
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="searchMain">
                                        <ApptSearch
                                            handleSearchResult={this.handleSearchResult}
                                            handleFinalSearchResult={this.handleFinalexpireSearchResult} />
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="excelMain">
                                        <ApptExcel data={this.state.cancelAppt} />
                                    </Col>
                                </Row> : null}

                            <Row gutter={16} className="appointmentMain">
                                {this.state.cancelAppt.length !== 0 ?
                                    (this.state.cancelAppt.map((appt, index) =>
                                        <Col xs={24} sm={24} md={20} lg={20} xl={20} className="expiredappointmentPatient" key={appt.appointmentId}>

                                            <Row gutter={16} className="apptMainRow">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    {/* {this.state.userType !== "2" ?
                                                        <Statistic title={appt.appointmentType !== 2 ?
                                                            "Doctor's Name" : "Meeting Organizer"}
                                                            value={appt.drName} valueStyle={{ fontSize: '16px' }} />
                                                        :
                                                        <Statistic title={appt.appointmentType !== 2 ? "Patient's Name" : "Meeting Organizer"}
                                                            value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                                                    } */}

                                                    <Statistic title="Patient's Name"
                                                        value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                {appt.appointmentType !== 2 ?
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        {/* {this.state.userType === "3" ?
                                                            <Statistic title="Patient's Name" value={appt.ptName} valueStyle={{ fontSize: '16px' }} />
                                                            :
                                                            <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                                                        } */}
                                                        <Statistic title=
                                                            "Doctor's Name"
                                                            value={appt.drName} valueStyle={{ fontSize: '16px' }} />
                                                    </Col> : null}
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6} className="appointmentJoinContainer">
                                                    <Statistic title="Confirmed appointment time" value={moment(appt.appointmentDateTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '16px' }} />
                                                </Col>

                                                {this.state.userType !== "5" ?

                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <Statistic title="Created By" value={appt.createdBy} valueStyle={{ fontSize: '16px' }} />
                                                    </Col> : null}

                                                {this.state.userType !== "5" ?
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <Statistic title="Created Time" value={moment(appt.createTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '16px' }} />
                                                    </Col> : null}


                                                {this.state.userType !== "5" ?
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        {appt.modBy !== "Unknown" ?
                                                            <Statistic title="Last Modified By" value={appt.modBy} valueStyle={{ fontSize: '16px' }} /> : null}
                                                    </Col> : null}

                                                {this.state.userType !== "5" ?
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        {appt.modTime !== null ?
                                                            <Statistic title="Last Modification Time" value={moment(appt.modTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '16px' }} /> : null}
                                                    </Col>

                                                    : null}

                                                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                                    <Statistic title="Purpose" value={appt.data} valueStyle={{ fontSize: '16px' }} />
                                                </Col>
                                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                    <p className="expiredText">{appt.status}</p>
                                                </Col>

                                            </Row>

                                            {/* <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                                        <Col span={8} >

                                            <Statistic title="Doctor Name" value="Dr.Rajendra Prasad" valueStyle={{ fontSize: '16px' }} />
                                        </Col>
                                        <Col span={8} >
                                            <Statistic title="Appoint Time" value={new Date().toLocaleString()} valueStyle={{ fontSize: '16px' }} />
                                        </Col>
                                        <Col span={8} className="appointmentJoinContainer">
                                            
                                            <p className="expiredText">Expired</p>
                                        </Col>
                                        <Col span={24}>
                                            <Statistic title="Purpose" value="Some Problem" valueStyle={{ fontSize: '16px' }} />
                                        </Col>
                                    </Row> */}
                                        </Col>)) : null}


                            </Row>
                        </TabPane>
                        {this.state.userType !== "5" ?
                            <TabPane tab="Create Appointment" key="3" >
                                <Row gutter={16} className="appointmentMain">
                                    <Col xs={24} sm={24} md={20} lg={20} xl={20} className="createAppointmentMain">

                                        <Form
                                            name="basic"
                                            layout="vertical"
                                            initialValues={{
                                                remember: true,
                                                prefix: '+65',
                                            }}
                                            requiredMark={false}
                                            onFinish={this.onFinish}
                                            onFinishFailed={this.onFinishFailed}
                                            scrollToFirstError
                                            size="large"
                                        >
                                            <Form.Item
                                                name="apptType"
                                                label="Appointment Type"
                                                className="selectLabel"
                                                initialValue="1"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select the Appointment Type!',
                                                    },
                                                ]}

                                            >
                                                <Select onChange={this.onAppointmentTypeChange} allowClear placeholder="select">
                                                    <Select.Option value="1">Consultation</Select.Option>
                                                    {/* <Select.Option value="2">Meeting</Select.Option> */}
                                                </Select>
                                            </Form.Item>

                                            <div>
                                                <h5>Patient Details</h5>
                                                <Form.Item
                                                    name="phone"
                                                    label="Phone Number"
                                                    className="InputLabel addonStyle"


                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your phone number!',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                               
                                                                if (!value || getFieldValue('phone').replace(/\D/g, "") === getFieldValue('phone')) {
                                                                    return Promise.resolve();
                                                                }

                                                                return Promise.reject(new Error('Please enter numbers only'));
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input maxLength={12}
                                                        onBlur={this.onBlurPhoneNo}
                                                        onChange={this.handlePhoneNo}

                                                        addonBefore={<Form.Item name="prefix" noStyle>
                                                            <Select

                                                                onChange={this.onSelectCountryCode}
                                                                style={{
                                                                    width: 'auto',
                                                                }}
                                                            >
                                                                {this.state.contryPhoneCodes &&
                                                                    this.state.contryPhoneCodes.map((code, index) =>
                                                                        <Option value={code} key={index}>{code}</Option>)}

                                                            </Select>
                                                        </Form.Item>}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </Form.Item>

                                                {this.state.isUserAvailable === false ?
                                                    <Form.Item
                                                        name="email"
                                                        label="E-mail"
                                                        className="InputLabel"

                                                        rules={[
                                                            {
                                                                type: 'email',
                                                                message: 'The input is not valid E-mail!',
                                                            },
                                                            {
                                                                required: true,
                                                                message: 'Please input your E-mail!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input onChange={this.handlePatientEmail} />
                                                    </Form.Item> :
                                                    <Form.Item
                                                        name="email"
                                                        label="E-mail"
                                                        className="selectLabel"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select the Email!',
                                                            },
                                                        ]}

                                                    >
                                                        <Select>
                                                            {this.state.userDetailsviaPhone.map((userType, index) =>
                                                                <Select.Option key={index} value={userType.email}>{userType.email}</Select.Option>
                                                            )}

                                                        </Select>
                                                    </Form.Item>}
                                                {this.state.isUserAvailable === false ?
                                                    <Form.Item
                                                        name="patientName"
                                                        label="Patient Name"
                                                        className="InputLabel"


                                                        // tooltip="What do you want others to call you?"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input Patient name!',
                                                                whitespace: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Input onChange={this.handlePatientName} />
                                                    </Form.Item> :
                                                    <Form.Item
                                                        name="patientName"
                                                        label="Patient Name"
                                                        className="selectLabel"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select the Patient name!',
                                                            },
                                                        ]}

                                                    >
                                                        <Select>
                                                            {this.state.userDetailsviaPhone.map((userType, index) =>
                                                                <Select.Option key={index} value={userType.userName}>
                                                                    {userType.userName}</Select.Option>
                                                            )}

                                                        </Select>
                                                    </Form.Item>}
                                                {this.state.isUserAvailable === false ?
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ display: "flex" }}>
                                                        <Button type="primary"
                                                            disabled={this.state.isCreatePatientdisabled}
                                                            onClick={this.hadleCreatePatient}
                                                            style={{ margin: "auto" }}> Create Patient</Button>
                                                    </Col> : null}
                                                {this.state.isPhonenoUpdated === true ?
                                                    <div>

                                                        <h5>Appointment Details</h5>
                                                        <Form.Item
                                                            name="doctorName"
                                                            label="Doctor Name"
                                                            className="InputLabel"
                                                            rules={[{ required: true, message: 'Please Enter Doctor Name!' }]}
                                                        >
                                                            <AutoComplete options={DoctorOptions}
                                                                onChange={this.handleDoctorNameChange}>
                                                                <Input />
                                                            </AutoComplete>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="consName"
                                                            label="Consultant Name"
                                                            className="InputLabel"
                                                            rules={[{ required: true, message: 'Please Enter Consultant Name!' }]}
                                                        >
                                                            <AutoComplete options={ConsultantOptions}
                                                                onChange={this.handleConsNameChange} >
                                                                <Input />
                                                            </AutoComplete>
                                                        </Form.Item>
                                                        {/* <Form.Item
                                                        name="Duration"
                                                        label="Duration"
                                                        className="selectLabel"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select the Duration!',
                                                            },
                                                        ]}

                                                    >
                                                        <Select>
                                                            
                                                                    <Select.Option  value="1800">
                                                                    30 Minutes</Select.Option>
                                                                    <Select.Option  value="3600">
                                                                    1 Hour</Select.Option>
                                                                    <Select.Option  value="5400">
                                                                    1:30 Hours</Select.Option>
                                                                    <Select.Option  value="7200">
                                                                    2 Hours</Select.Option>
                                                                    <Select.Option  value="9000">
                                                                    2:30 Hours</Select.Option>
                                                                    <Select.Option  value="10800">
                                                                    3:00 Hours</Select.Option>
                                                                    
                                                        

                                                        </Select>
                                                    </Form.Item> */}

                                                        <Row gutter={[16]}>
                                                            <Col xs={24} sm={24} md={24} lg={12} xl={12} >

                                                                <Form.Item
                                                                    label="Date"
                                                                    name="date"
                                                                    initialValue={this.handleinitaildateValue()}

                                                                    className="InputLabel">
                                                                    <DatePicker onChange={this.onChangedate}

                                                                        disabledDate={this.disabledDate}
                                                                        format="DD/MM/YYYY"
                                                                        onOk={this.onOk} className="AppointmentDatePicker" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                                                                <Form.Item
                                                                    label="Time"
                                                                    name="time"
                                                                    initialValue={moment()}

                                                                    className="InputLabel">

                                                                    <ClentTimePicker
                                                                        isTimeValid={this.state.isSelectedModTimeValid}
                                                                        PickerWidth="100%"
                                                                        pickerHeight="40px"
                                                                        handleModApptTime={this.handleModApptTime} />

                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Form.Item
                                                            label="Purpose"
                                                            name="purpose"
                                                            className="InputLabel"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input purpouse!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input.TextArea />
                                                        </Form.Item>
                                                        <Form.Item {...tailLayout}>
                                                            <Button type="primary" htmlType="submit" >
                                                                Submit
                                                            </Button>
                                                        </Form.Item>
                                                    </div> : null}

                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane> : null}


                        {(this.state.userType === "7" || this.state.userType === "1" ) &&
                            <TabPane tab="Search Appointments" key="4" >
                                <Row gutter={16} className="appointmentMain">

                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="findApptfor">
                                        <h5>Find Appointments for:</h5>
                                        <Select defaultValue="Patient"
                                            className="apptSelectfor"
                                            // style={{ width: '10%' }}
                                            size="middle"
                                            onChange={this.handleSearchTypeChange}>
                                            <Option value="Patient">Patient</Option>
                                            <Option value="Doctor">Doctor</Option>
                                            <Option value="Consultant">Consultant</Option>
                                            <Option value="CreatedBy">Created By</Option>
                                            <Option value="ModBy">Modified By</Option>
                                            <Option value="All">All</Option>

                                        </Select>

                                    </Col>

                                    <h5 style={{ width: "100%", paddingLeft: 8 }}>Search criteria:</h5>
                                    <Col xs={24} sm={24} md={12} lg={5} xl={5} className="searchFields">
                                        <Select defaultValue="Phone Number"
                                            className="apptSelectforphno"
                                            // style={{ width: '10%' }}
                                            size="middle"
                                            onChange={this.handleSearchphnoChange}>
                                            <Option value="Phone">Phone Number</Option>
                                            <Option value="Email">Email ID</Option>
                                            <Option value="Name">Name</Option>

                                        </Select>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={5} xl={5} className="searchFields" >
                                        <Input placeholder="Enter text"
                                            value={this.state.searchCriteriaInp}
                                            onChange={this.handleSearchCriteriaInp} />
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={5} xl={5} className="searchFields">
                                        <DatePicker onChange={this.handleStartChange}
                                            defaultValue={moment()} format='YYYY-MM-DD'
                                            style={{ width: "100%" }} />
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={4} xl={5} className="searchFields">
                                        <DatePicker onChange={this.handleEndChange}
                                            style={{ width: "100%" }} />
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={4} xl={4} className="searchFields">
                                        <Button type="primary"
                                            onClick={this.handleCsSearch}> Search</Button>
                                    </Col>




                                </Row>
                                <hr />

                                <Row gutter={16} className="appointmentMain">
                                    <h6 style={{ textAlign: "center", width: "100%" }} >No of appointments : {this.state.searchApptResults.length}</h6>
                                    {this.state.searchApptResults.length !== 0 ?


                                        <Row gutter={16} className="Intsearchmain">
                                            <Col xs={24} sm={24} md={24} lg={12} xl={12} className="searchMain">
                                                <ApptSearch
                                                    handleSearchResult={this.handleSearchResult}
                                                    handleFinalSearchResult={this.handleFinalApptSearchResult} />
                                            </Col>

                                            <Col xs={24} sm={24} md={24} lg={12} xl={12} className="excelMain">
                                                <ApptExcel data={this.state.searchApptResults} />
                                            </Col>
                                        </Row> : null}


                                    {/* {this.state.searchApptResults !== 0 ?
                                    <ApptExcel data={this.state.searchApptResults} />:null} */}
                                    {this.state.searchApptResults &&
                                        (this.state.searchApptResults.map((appt, index) =>
                                            <Col xs={24} sm={24} md={20} lg={20} xl={20}
                                                className={appt.status !== "Active" ? "expiredappointmentPatient" :
                                                    this.state.userType !== "5" ? "appointmentDoctor" :
                                                        "appointmentPatient"}
                                                key={appt.appointmentId}>

                                                <Row gutter={16} className="apptMainRow">
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>


                                                        <Statistic title="Patient's Name"
                                                            value={appt.ptName}
                                                            prefix={<Popover content={this.content(appt)}
                                                                title="Details" trigger="click" onClick={() => this.handlePopClick(appt)}>
                                                                <InfoCircleFilled className="InfoApptName" />
                                                            </Popover>}
                                                            // prefix = {
                                                            //     <Button type="ghost" onClick = {this.content(appt)}>
                                                            //         <InfoCircleFilled  className = "InfoApptName"/>
                                                            //     </Button>
                                                            // }



                                                            valueStyle={{ fontSize: '16px' }} />

                                                    </Col>
                                                    {appt.appointmentType !== 2 ?
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                                                            <Statistic title=
                                                                "Doctor's Name"
                                                                value={appt.drName} valueStyle={{ fontSize: '16px' }} />
                                                        </Col> : null}
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="appointmentJoinContainer">
                                                        <Statistic title="Confirmed appointment time"

                                                            value={this.handleapptTimeGmt(appt.appointmentDateTime)}
                                                            valueStyle={{ fontSize: '16px' }} />
                                                    </Col>

                                                    {this.state.userType !== "5" ?

                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                                            <Statistic title="Created By" value={appt.createdBy} valueStyle={{ fontSize: '12px' }} />
                                                        </Col> : null}

                                                    {this.state.userType !== "5" ?
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                                            <Statistic title="Created Time" value={moment(appt.createTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '12px' }} />
                                                        </Col> : null}


                                                    {this.state.userType !== "5" ?
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                                            {appt.modBy !== "Unknown" ?
                                                                <Statistic title="Last Modified By" value={appt.modBy} valueStyle={{ fontSize: '12px' }} /> : null}
                                                        </Col> : null}

                                                    {this.state.userType !== "5" ?
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                                            {appt.modTime !== null ?
                                                                <Statistic title="Last Modification Time" value={moment(appt.modTime).format('MMMM Do YYYY, h:mm:ss a')} valueStyle={{ fontSize: '12px' }} /> : null}
                                                        </Col>

                                                        : null}


                                                    <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                                        <Statistic title="Purpose" value={appt.data} valueStyle={{ fontSize: '16px' }} />
                                                    </Col>
                                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                        {appt.status === "Active" ?
                                                            <Button
                                                                className="appointmentJoinDoctor"
                                                                type="primary"
                                                                size="middle"
                                                                // disabled
                                                                onClick={() => this.handleJoinCall(index, appt)}
                                                            >Join call</Button> : <p className="expiredText">{appt.status}</p>}
                                                    </Col>
                                                    {appt.status === "Active" ?
                                                        <div>
                                                            {this.state.userType !== "5" ?
                                                                <Button type="danger" className="apptEditButton"
                                                                    onClick={() => this.handleEditLink(appt, index)}>
                                                                    <EditFilled style={{ color: "#000000", fontSize: "20px" }} />
                                                                </Button> : null}
                                                            {this.state.userType !== "5" ?
                                                                <Popconfirm
                                                                    title="Are you sure to delete this task?"
                                                                    onConfirm={() => this.handleDeleteConfirm(index, appt)}
                                                                    onCancel={this.handleDeletecancel}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button type="danger" className="apptCancelButton">
                                                                        <img src={deleteIcon} alt="delete Icon" className="deleteButtonImg" />
                                                                    </Button>

                                                                </Popconfirm> : null}
                                                            {this.state.userType !== "5" ?
                                                                <Button type="danger" className="apptCancelButton1"
                                                                    onClick={() => this.handleInviteLink(appt, index)}>
                                                                    Invite
                                                                </Button> : null}
                                                        </div> : null}

                                                </Row>
                                            </Col>))}


                                </Row>
                            </TabPane>}

                        {this.state.userType !== "5" ?
                            <TabPane tab="Meetings" key="5" className="meetingsTabsMain" id="123"
                            >
                                <h6 style={{ textAlign: "right" }}>No of Meetings : {this.state.activeMeet.length}</h6>
                                {this.state.activeMeet.length !== 0 ?


                                    <Row gutter={16} className="Intsearchmain">
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="searchMain">
                                            <ApptSearch
                                                handleSearchResult={this.handleSearchResult}
                                                handleFinalSearchResult={this.handleActiveMeetSearchResult} />
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="excelMain">
                                            <ApptExcel data={this.state.activeMeet} />
                                        </Col>
                                    </Row> : null}
                                <MeetingDetails
                                    handleJoinMeet={this.handleJoinCallMeet}
                                    handleUserMeetings={this.handleUserMeetings}
                                    data={this.state.activeMeet} />
                            </TabPane> : null}
                        {this.state.userType !== "5" ?
                            <TabPane tab="Expired Meetings" key="6" >
                                <h6 style={{ textAlign: "right" }}>No of Meetings : {this.state.expiredMeet.length}</h6>
                                {this.state.expiredMeet.length !== 0 ?


                                    <Row gutter={16} className="Intsearchmain">
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="searchMain">
                                            <ApptSearch
                                                handleSearchResult={this.handleSearchResult}
                                                handleFinalSearchResult={this.handleExpireMeetSearchResult} />
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="excelMain">
                                            <ApptExcel data={this.state.expiredMeet} />
                                        </Col>
                                    </Row> : null}
                                <MeetingDetails
                                    handleUserMeetings={this.handleUserMeetings}
                                    data={this.state.expiredMeet} />
                            </TabPane> : null}
                        {this.state.userType !== "5" ?
                            <TabPane tab="Create Meeting" key="7" >

                                <Row gutter={16} className="appointmentMain">
                                    <Col xs={24} sm={24} md={20} lg={20} xl={20} className="createAppointmentMain">

                                        <Form
                                            // {...layout}

                                            name="basic"
                                            layout="vertical"
                                            initialValues={{
                                                remember: true,
                                                prefix: '+65',
                                            }}
                                            requiredMark={false}
                                            onFinish={this.onMeetingFinish}
                                            onFinishFailed={this.onFinishMeetingFailed}
                                            scrollToFirstError
                                            size="large"
                                        >

                                            <h5>Meeting Details</h5>
                                            {/* <Form.Item
                                            name="organizer"
                                            label="Organizer"
                                            className="InputLabel"

                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input organizer name!',
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item> */}
                                            <Row gutter={[16]}>

                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                    <Chips
                                                        style={{ width: "100%" }}
                                                        className="EmailParticipants"
                                                        chips={[]}
                                                        placeholder='Add a Participant...'
                                                        save={data => this.setState({ emailParticipants: data })}
                                                        pattern={pattern}
                                                        required={true}
                                                        title="Participant's Name:"
                                                        limit='5'
                                                        limitNotification={(data) =>
                                                            data.length >= 5 ?
                                                                message.warning('This is a warning message') : null}
                                                    ></Chips>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >

                                                    <Form.Item
                                                        name="Duration"
                                                        label="Duration"
                                                        className="selectLabel"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select the Duration!',
                                                            },
                                                        ]}

                                                    >
                                                        <Select>

                                                            <Select.Option value="1800">
                                                                30 Minutes</Select.Option>
                                                            <Select.Option value="3600">
                                                                1 Hour</Select.Option>
                                                            <Select.Option value="5400">
                                                                1:30 Hours</Select.Option>
                                                            <Select.Option value="7200">
                                                                2 Hours</Select.Option>
                                                            <Select.Option value="9000">
                                                                2:30 Hours</Select.Option>
                                                            <Select.Option value="10800">
                                                                3:00 Hours</Select.Option>



                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} sm={24} md={24} lg={12} xl={12} >

                                                    <Form.Item
                                                        label="Date"
                                                        name="date"
                                                        initialValue={moment()}

                                                        className="InputLabel">
                                                        <DatePicker onChange={this.onChangedate}

                                                            disabledDate={this.disabledDate}
                                                            format="DD/MM/YYYY"
                                                            onOk={this.onOk} className="AppointmentDatePicker" />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} sm={24} md={24} lg={12} xl={12}
                                                    style={{ position: "relative" }}>
                                                    <Form.Item
                                                        label="Time"
                                                        name="time"
                                                        initialValue=""
                                                        className="InputLabel">
                                                        <ClentTimePicker
                                                            isTimeValid={this.state.isSelectedModTimeValid}
                                                            PickerWidth="100%"
                                                            pickerHeight="40px"
                                                            handleModApptTime={this.handleModApptTime} />

                                                    </Form.Item>
                                                    {this.state.isSelectedModTimeValid === false ?
                                                        <p className="ValidationMessage">Please Select the time</p> : null}
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                    <Form.Item
                                                        label="Purpose"
                                                        name="purpose"
                                                        className="InputLabel"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input purpouse!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input.TextArea />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item {...tailLayout}>
                                                <Button type="primary" htmlType="submit" >
                                                    Submit
                                                </Button>
                                            </Form.Item>

                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane> : null}
                    </Tabs>
                </div>

                <Modal title="Invitation Link"
                    visible={this.state.isInvitationModalVisible}
                    className="payConfirmModal"
                    footer={null}
                    onOk={this.handleInvitationModalOk}
                    onCancel={this.handleInvitationModalCancel}>
                    <Result
                        status="success"
                        title="Successfully Generated Invitation Link"
                        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                        extra={[
                            <p>{this.state.urlLink}</p>,
                            <CopyToClipboard text={this.state.urlLink}
                                onCopy={this.handleCopyText}>
                                <Button type="primary">Copy Link</Button>
                            </CopyToClipboard>,

                        ]}
                    />

                </Modal>
                <Modal title="Alert"
                    visible={this.state.ErrorInJoinCall}
                    className="payConfirmModal"
                    footer={null}
                    onOk={this.handleErrorInJoinCallModalOk}
                    onCancel={this.handleErrorInJoinCallModalCancel}>
                    <Result
                        status="error"
                        title="Meeting not yet started"
                    // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                    // extra={[
                    //     <p>{this.state.urlLink}</p>,
                    //     <CopyToClipboard text={this.state.urlLink}
                    //         onCopy={this.handleCopyText}>
                    //         <Button type="primary">Copy Link</Button>
                    //     </CopyToClipboard>,

                    // ]}
                    />
                    <div style={{ with: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>


                        <Button type="danger" onClick={this.handleErrorInJoinCallModalOk}>Ok</Button>
                    </div>

                </Modal>
                <Modal title="Appointment Modification"
                    visible={this.state.isModApptModalVisible}
                    className="payConfirmModal"
                    footer={null}
                    destroyOnClose={true}
                    onOk={this.handleModApptModalOk}
                    onCancel={this.handleModApptModalCancel}>

                    <p className="modUserDetailsModal">Patient's Name : <span>{this.state.apptModModalDetials.ptName}</span></p>
                    <p className="modUserDetailsModal">Doctor's Name :
                        <Select placeholder="select Doctor" onChange={this.handleModApptDocChange}>
                            {this.state.doctorsData.map((docname, index) =>
                                <Option value={docname.userId} key={docname.userId}>{docname.userName}</Option>)}

                        </Select>
                    </p>
                    <p className="modUserDetailsModal">Consultant's Name :
                        <Select placeholder="select Consultant" onChange={this.handleModApptConChange}>
                            {this.state.consData.map((docname, index) =>
                                <Option value={docname.userId} key={docname.userId}>{docname.userName}</Option>)}

                        </Select>
                    </p>
                    <p className="modUserDetailsModal">Date :
                        <DatePicker onChange={this.modApptDateChange}
                            size="middle"
                            style={{ width: "200px" }}
                            disabledDate={this.disabledDate}
                            format="DD/MM/YYYY"
                            onOk={this.onOk} className="AppointmentDatePicker" />
                    </p>
                    <div className="modUserDetailsModal">Time :

                        <ClentTimePicker
                            isTimeValid={this.state.isSelectedModTimeValid}
                            handleModApptTime={this.handleModApptTime}
                            PickerWidth="200px"
                            pickerHeight="30px" />

                    </div>

                    <div style={{ with: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button type="primary" onClick={this.handleModApptSubmit}
                            style={{ marginTop: "15px" }} >
                            Update
                        </Button>
                    </div>
                </Modal>

            </div>
        );
    }
}
export default Appointment;

// const mapStateToProps = state => {
//     return{
//         userName : state.userDetails.userName,
//         pswd : state.userDetails.pswd,
//         loginStatus : state.userDetails.loginStatus,
//         userType : state.userDetails.userType
//     } 
// }

// export default connect(mapStateToProps,null) (Appointment);