import BaseUrl from "./baseUrl";
import axios from 'axios';

const api = axios.create({
    baseURL: BaseUrl.rootUrl,
});

export async function  SessionDetailsServices(sessionDetails) {
    return api.post('getToken', sessionDetails,{timeout: 10000}).then(response => {
       
        return response;
    }) .catch((error) => {
    
        
        return error.response;
      })
}