import React, { Component } from 'react';
import { Input, Button, Modal, } from 'antd';
import { UserOutlined,MailFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../config.json';


import logo from '../assets/images/sgm_analytics.png';

import "../css/login.css";
import * as actionTypes from "../store/actions"

// import { UserLoginService } from "../services/loginService";
// import state from 'opentok-accelerator-core/dist/state';
import {AdhocAppointmentServices,AdhocMeetingServices} from "../services/adhocServices";
import {SessionDetailsServices} from "../services/sessionDetailsService";




class Adhoc extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userDetails: {},
            userName: "",
            email: "",
            sessionId:"",

        }
    }
    componentDidMount = () => {
       
        const queryString = window.location.hash.replace(/\D/g, "");

        let isMeeetingAdhoc = window.location.hash.search("AdhocMeet")
    
        // queryString.replace(/\D/g, "")
   

        // const queryParams = new URLSearchParams(window.location.hash)
        // const name = queryParams.get('appointmentId');
       
        let sessionDestails = {
            appointmentId:queryString.toString()
        }

        if(isMeeetingAdhoc === -1){
            AdhocAppointmentServices(sessionDestails).then((response) => {
                if (response.status === 400) {
                    Modal.error({
                        title: "Error",
                        content: response.data,
                    });
                } else {
                      
             
                let result = response.data;
                
                config.sessionId = result.sessionId;
                
               this.setState({sessionId:result.sessionId})
                }
                
               
    
            })
        } else{
            AdhocMeetingServices(sessionDestails).then((response) => {
                if (response.status === 400) {
                    Modal.error({
                        title: "Error",
                        content: response.data,
                    });
                } else {
                      
         
                let result = response.data;
                
                config.sessionId = result.sessionId;
                
               this.setState({sessionId:result.sessionId})
                }
                
               
    
            })
        }
        
    }

    
    handleUserName = (e) => {
        
        let username = e.target.value;
        this.setState({ userName: username })

    }

    handleEmail = (e) => {
     
        let email = e.target.value;
        this.setState({ email })
    }

  

    handleLogin = () => {

        if (this.state.userName === "" || this.state.email === "") {
            Modal.warning({
                title: (this.state.userName === "" && this.state.email === "") ? 'No User crendential' :
                    this.state.email === "" ? "No Email address" : "No User name"
                ,
                content: (this.state.userName === "" && this.state.email === "") ?
                    'Please enter username and Email' :
                    this.state.email === "" ? "Please enter the Email address" :
                        "Please enter the user name",
            });
        } else {

            let isMeeetingAdhoc = window.location.hash.search("AdhocMeet")

            let sessionDestails = {
                userId: this.state.userName,
                sessionId: this.state.sessionId,
                userType: "6",
                sessionType: isMeeetingAdhoc === -1 ?  "Adhoc":"AdhocMeeting" 
            }

      

            SessionDetailsServices(sessionDestails).then((response) => {
                      
                
                        if(response.status === 400){
                            Modal.error({
                                title: "Error",
                                content:response.data,
                            });
                        }else
                        {

                        
                        let result = response.data

                        if(result.apiKey !== ""){
                            config.loginStatus = "true"
                            config.apiKey = result.apiKey;
                            config.sessionId = this.state.sessionId;
                            config.token = result.token;
                            config.userName = this.state.userName;
                            config.userType = "6"
                            localStorage.setItem('userName',this.state.userName)
                            localStorage.setItem('loginStatus',true)
                            localStorage.setItem('userType',"6")
                            localStorage.setItem('apiKey',result.apiKey)
                            localStorage.setItem('sessionId',this.state.sessionId)
                            localStorage.setItem('token',result.token)
                          
                            if (config.apiKey !== "" && config.sessionId !== "" && config.token !== "") {
                                this.props.history.push("/VideoCalling");
                            }
                        }else{
                            Modal.error({
                                title: "Error",
                                content: result.errorString,
                            });
                        }
                    }
                        

            })

        }

    }


    render() {
        return (
            <div className="adhoc_body">
                <div className="Loginlogo">
                    <img src={logo} alt="logo" width="auto" />
                </div>
                <div className="adhocBodyMain">
                    <div className="loginCard">
                        <h5 className="loginHeadingText">Enter Your Details</h5>
                        <Input placeholder="Username"
                            className="inputUsername"
                            size="large"
                            value={this.state.userName}
                            onPressEnter={this.handleLogin}
                            onChange={this.handleUserName}
                            prefix={<UserOutlined className="site-form-item-icon" />} 
                            />
                        <Input
                            placeholder="Email"
                            className="inputPassword"
                            size="large"
                            value={this.state.email}
                            onPressEnter={this.handleLogin}
                            onChange={this.handleEmail}
                            prefix={<MailFilled className="site-form-item-icon" />}
                            // iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                        <Button
                            className="loginSubmitButton"
                            size="large"
                            onClick={()=>this.handleLogin()}
                            
                        >Submit</Button>

                    </div>
                </div>

            </div>
        );
    }
}

export default Adhoc;