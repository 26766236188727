import React, { Component } from 'react';
import {
    Statistic, Row, Col, Button, Modal, Tabs,
    Select, Input, Form, Checkbox, DatePicker, Space, Popconfirm, message, Result, AutoComplete,
    Popover
} from 'antd';
// import { DeleteFilled } from '@ant-design/icons';
import { EditFilled, InfoCircleFilled } from "@ant-design/icons";

import config from '../config.json';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _ from "lodash";
import moment from 'moment';
import ClentTimePicker from "../components/timePicker1";
import { SessionDetailsServices } from "../services/sessionDetailsService";

import {UserMeetingtServices,createMeetingService,modifyMeetService} from "../services/meetingServices"
import "../css/appointment.css";


import deleteIcon from "../assets/images/trash.png"




class MeetingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInvitationModalVisible: false,
            urlLink: "",
            isModMeetModalVisible:false,
            isSelectedModTimeValid:true,
            meetModModalDetials: {},
            modMeetDate:null,
            selectedModTime: "",
        }
    }

    
    handleapptTimeGmt = (time) => {
        let appttime = moment(time).format('MMMM Do YYYY, h:mm:ss a')
        return appttime;
    }

    content = (appt) => {
        this.props.content(appt);
    }
    handlePopClick = (appt) => {
        this.props.handlePopClick(appt);
    }

    handleInvitationModalOk = () => {
        this.setState({ isInvitationModalVisible: false });
    }
    handleInvitationModalCancel = () => {
        this.setState({ isInvitationModalVisible: false });
    }
    handleCopyText = () => {
        // console.log(this.state.urlLink, "copied")
    }
    handleInviteLink = (appt, index) => {

        // console.log(appt, "appt", index, "index")
        let appointmentId = appt.appointmentId
        // console.log(appointmentId, "appointmentId")
        let urlText = `http://izoom.ids-group.co/#/AdhocMeet?appointmentId=${appointmentId}`
        this.setState({ isInvitationModalVisible: true, urlLink: urlText })
    }
    handleDeleteConfirm = (index, appt) => {
        const userId = localStorage.getItem('userId');
        // console.log(appt, "appt")
        let modMeetObj = {
            apptID: appt.appointmentId,
            apptStatus: "Expired",
            logUser: userId,
        }
        // console.log(modMeetObj, "modMeetObj")
        modifyMeetService(modMeetObj).then((response) => {
            this.props.handleUserMeetings();
        }), (res) => {
            message.error('Error in delete Appointment !');
        }

    }

    handleDeletecancel = (e) => {
        // console.log(e);
        message.error('Rejected to delete !');
    }

    handleEditLink = (appt, index) => {
        // console.log(appt, "appt")
        // this.onDocConDetails();

        this.setState({ isModMeetModalVisible: true, meetModModalDetials: appt })
    }

    handleModMeetModalOk = () => {
        this.setState({ isModMeetModalVisible: false });
    }
    handleModMeetModalCancel = () => {
        this.setState({ isModMeetModalVisible: false });
    }
    modApptDateChange = (date) => {
        // console.log(date)
        this.setState({ modMeetDate: date })
    }
    disabledDate = current => {
        return moment(current).day() === 0 || current <= moment().startOf('day');
    }
    handleModApptTime = (time) => {
        // console.log(time,"time")
        this.setState({ selectedModTime: time },()=>this.handleMeetingValidations())
    }
    handleMeetingValidations = () =>{
        
        if(this.state.selectedModTime !== ""){
            this.setState({isSelectedModTimeValid:true})

        }else{
            this.setState({isSelectedModTimeValid:false})
        }
    }

    handleModMeetSubmit = () => {

        const userId = localStorage.getItem('userId');

        if (this.state.modMeetDate !== null && this.state.selectedModTime !== "") {


            let meetDate = new Date(new Date(this.state.modMeetDate).toDateString() + " " +
                this.state.selectedModTime).toGMTString().slice(0, 16)
            let meetTime = new Date(new Date(this.state.modMeetDate).toDateString() + " " +
                this.state.selectedModTime).toGMTString().slice(17, 22)

            // console.log(apptDate, "apptDate")


            let meetingObj = {
                organizer:userId,
                participants:[],
                date:moment(meetDate).format("YYYY-MM-DD"),
                time:meetTime,
                orgMeetDateTime: moment(moment(this.state.modMeetDate).format("YYYY-MM-DD") + " " +
                this.state.selectedModTime).format(),
                logUser: userId,
                reqType: "Modify",
                purpose:this.state.meetModModalDetials.data,
                apptType:"2",
                apptID:this.state.meetModModalDetials.appointmentId
            }
            console.log(meetingObj,"meetingObj")
            createMeetingService(meetingObj).then((response) => {
                // console.log(response)
                if (response.status === 400) {
                    Modal.error({
                        title: "Error",
                        content: response.data,
                    });
                } else {
                if (response.data !== undefined) {
                    message.success('Successfully Meeting Modified !');
                    this.setState({ isModMeetModalVisible: false })
                } else {
                    message.error('Error in Meeting Modification !');
                }
                
                this.props.handleUserMeetings();
            }

            })
            // , (response) => {
            //     message.error('Error in Meeting Modification !');
            //     // this.handleUserMeetings();
            // }


        } else {
            message.error('Please fill all the details!');
        }
        
    }

    handleJoinCall = (index, appt) => {
        this.props.handleJoinMeet(index, appt)
        // console.log(index, "index")
        // console.log(appt, "appt")
        // localStorage.setItem('appt', JSON.stringify(appt))
        // const userId = localStorage.getItem('userId');
        // let sessionDestails = {
        //     userId: userId,
        //     sessionId: appt.sessionId,
        //     userType: localStorage.getItem('userType'),
        //     sessionType: "RegularMeeting"
        // }
        // console.log(sessionDestails,"sessionDestails")
        // SessionDetailsServices(sessionDestails).then((response) => {
        //     console.log(response, "response")
        //     let result = response.data
        //     config.apiKey = result.apiKey;
        //     config.sessionId = appt.sessionId;
        //     config.token = result.token;
        //     config.userName = this.state.userName;
        //     localStorage.setItem('apiKey', result.apiKey)
        //     localStorage.setItem('sessionId', appt.sessionId)
        //     localStorage.setItem('token', result.token)
        //     console.log(config, "apiKey")
        //     if (config.apiKey !== "" && config.sessionId !== "" && config.token !== "") {
        //         this.props.history.push("VideoCalling");
        //     } else {
        //         this.setState({ ErrorInJoinCall: true })
        //     }

        // })

    }

    render() {
        let meetings = this.props.data;
        // console.log(meetings,"meetings")
        return (

            <div>
            <Row gutter={16} className="appointmentMain">
                {meetings.length !== 0 ?
                    (meetings.map((appt, index) =>
                        <Col xs={24} sm={24} md={20} lg={20} xl={20}
                            className={this.state.userType !== "5" ? "appointmentDoctor" : "appointmentPatient"}
                             key={appt.appointmentId}
                            >
                            <Row gutter={16} className="apptMainRow">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Statistic title="Oraganizer Name"
                                        value={appt.Organizer}
                                        valueStyle={{ fontSize: '16px' }} />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6} className="appointmentJoinContainer">
                                    <Statistic title="Confirmed meeting time" 
                                        value={this.handleapptTimeGmt(appt.appointmentDateTime)}
                                        valueStyle={{ fontSize: '16px' }} />
                                </Col>
                               
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Statistic title=
                                            "Participant's Names"
                                            value={appt.particpants === "" ? "Empty" :appt.particpants } 
                                            valueStyle={{ fontSize: '16px',width:"100%",overflowWrap: "anywhere" }} />
                                </Col> 
                                {/* <Col span={6}>
                                    <Statistic title="Consultant's Name" value={appt.consName} valueStyle={{ fontSize: '16px' }} />
                                </Col> */}
                                

                                {this.state.userType !== "5" ?

                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                        <Statistic title="Created By" 
                                        value={appt.createdBy} 
                                        valueStyle={{ fontSize: '12px' }} />
                                    </Col> : null}

                                {this.state.userType !== "5" ?
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                        <Statistic title="Created Time" 
                                        value={moment(appt.createTime).format('MMMM Do YYYY, h:mm:ss a')} 
                                        valueStyle={{ fontSize: '12px' }} />
                                    </Col> : null}


                                {this.state.userType !== "5" ?
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                        {appt.modBy !== "Unknown" ?
                                            <Statistic title="Last Modified By" 
                                            value={appt.modBy} 
                                            valueStyle={{ fontSize: '12px' }} />
                                             : null} 
                                    </Col> : null}

                                {this.state.userType !== "5" ?
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="modCreateStat">
                                        {appt.modTime !== null ?
                                            <Statistic title="Last Modification Time" 
                                            // value={moment(appt.modTime).format('MMMM Do YYYY, h:mm:ss a')} 
                                            valueStyle={{ fontSize: '12px' }} /> 
                                             : null}
                                    </Col>

                                    : null}


                                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                    <Statistic title="Purpose" 
                                    value={appt.data} 
                                    valueStyle={{ fontSize: '16px' }} />
                                </Col>

                                {appt.status === "Active" ?
                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Button
                                        className="appointmentJoinDoctor"
                                        type="primary"
                                        size="middle"
                                        // disabled
                                        onClick={() => this.handleJoinCall(index, appt)}
                                    >Join call</Button>
                                </Col>:null}
                                {this.state.userType !== "5" && appt.status === "Active" ?
                                    <Button type="danger" className="apptEditButton"
                                        onClick={() => this.handleEditLink(appt, index)}
                                        >
                                        <EditFilled style={{ color: "#000000", fontSize: "20px" }} />
                                    </Button> : null}
                                {this.state.userType !== "5" && appt.status === "Active"?
                                    <Popconfirm
                                        title="Are you sure to delete this task?"
                                        onConfirm={() => this.handleDeleteConfirm(index, appt)}
                                        onCancel={this.handleDeletecancel}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="danger" className="apptCancelButton">
                                            <img src={deleteIcon} alt="delete Icon" className="deleteButtonImg" />
                                        </Button>

                                    </Popconfirm> : null}
                                {this.state.userType !== "5" && appt.status === "Active" ?
                                    <Button type="danger" className="apptCancelButton1"
                                        onClick={() => this.handleInviteLink(appt, index)}
                                        >
                                        Invite
                                                </Button> : null}

                            </Row>
                        </Col>
                        )) : null} 


            </Row>
            <Modal title="Invitation Link"
                    visible={this.state.isInvitationModalVisible}
                    className="payConfirmModal"
                    footer={null}
                    onOk={this.handleInvitationModalOk}
                    onCancel={this.handleInvitationModalCancel}>
                    <Result
                        status="success"
                        title="Successfully Generated Invitation Link"
                        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                        extra={[
                            <p>{this.state.urlLink}</p>,
                            <CopyToClipboard text={this.state.urlLink}
                                onCopy={this.handleCopyText}>
                                <Button type="primary">Copy Link</Button>
                            </CopyToClipboard>,

                        ]}
                    />

            </Modal>
            <Modal title="Meeting Modification"
                    visible={this.state.isModMeetModalVisible}
                    className="payConfirmModal"
                    footer={null}
                    destroyOnClose={true}
                    onOk={this.handleModMeetModalOk}
                    onCancel={this.handleModMeetModalCancel}>

                    <p className="modUserDetailsModal">Organizer's Name : 
                        <span>{localStorage.getItem('userName')}</span>
                    </p>
                    
                    <p className="modUserDetailsModal">Date :
                        <DatePicker onChange={this.modApptDateChange}
                            size="middle"
                            style={{ width: "200px" }}
                            disabledDate={this.disabledDate}
                            format="DD/MM/YYYY"
                            onOk={this.onOk} className="AppointmentDatePicker" />
                    </p>
                    <div className="modUserDetailsModal">Time :
                    
                        <ClentTimePicker
                            isTimeValid={this.state.isSelectedModTimeValid}
                            handleModApptTime={this.handleModApptTime}
                            PickerWidth="200px"
                            pickerHeight="30px" />

                    </div>

                    <div style={{ with: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button type="primary" onClick={this.handleModMeetSubmit}
                            style={{ marginTop: "15px" }} >
                            Update
                                </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default MeetingDetails;