import BaseUrl from "./baseUrl";
import axios from 'axios';

const api = axios.create({
    baseURL: BaseUrl.rootUrl,
});

export async function  UserAppointmentServices(ApptDetails) {
    return api.post('getApptsList', ApptDetails,{timeout: 10000}).then(response => {
        
        return response;
    }) .catch((error) => {
    
       
        return error.response;
      })
}
export async function  patientApptdetailSearch(ApptDetails) {
    return api.post('getUsersList', ApptDetails,{timeout: 10000}).then(response => {
        
        return response;
    }) .catch((error) => {
    
      
        return error.response;
      })
}
export async function  createAppointmentService(ApptDetails) {
    return api.post('createAppt', ApptDetails,{timeout: 10000}).then(response => {
      
        return response;
    }) .catch((error) => {
    
       
        return error.response;
      })
}
export async function  modifyAppointmentService(ApptDetails) {
    return api.post('modAppt', ApptDetails,{timeout: 10000}).then(response => {
        
        return response;
    }) .catch((error) => {
    
      
        return error.response;
      })
}
export async function  findAppointmentService(ApptDetails) {
    return api.post('findApptsList', ApptDetails,{timeout: 10000}).then(response => {
      
        return response;
    }) .catch((error) => {
    
     
        return error.response;
      })
}