import React, { Component } from 'react';
import "../css/timePicker.css";
import { Menu, Dropdown,Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
class ClentTimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTime: "Select Time",
            visible: false,
            selectedMinute: "00",
            selectedHour: 10,
            activePM: false,
            activeAM: true,
            selectedDupHour: 10,
            // isSelectedTimeValid:true,

            // minHour: this.props.minHour,
            // maxHour: this.props.maxHour
        }
    }
    componentDidMount = () => {
        
    }
    handleDropDown = () => {
        this.setState({ visible: false });
    }
    handleOverlayClick = (text) =>{
      
        
        if(text === 'close'){
            
            let selectedTime = `${this.state.selectedHour}:${this.state.selectedMinute  } ${this.state.activePM === true?"PM":"AM"}`
      
           this.setState({selectedTime, visible: false });
           this.props.handleModApptTime(selectedTime)
           
        }
        
        // this.setState({selectedTime, visible: false });
    }
    handleVisibleChange = (flag) => {
        this.setState({ visible: flag });
    }

    handleHourIncrease = () => {
        if (this.state.selectedDupHour < 19) {
            let value = this.state.selectedDupHour + 1
        
            this.setState({ selectedDupHour: value })

            if (value > 12) {
                let hourvalue = value - 12;
                this.setState({ selectedHour: hourvalue })
            } else {
                this.setState({ selectedHour: value })
            }

            if (value >= 12) {
                this.setState({ activePM: true, activeAM: false })
            } else {
                this.setState({ activePM: false, activeAM: true })
            }
        }
    }
    handleMinuteIncrease = () => {
        let minute  = this.state.selectedMinute === "00" ?0:this.state.selectedMinute
        if (minute  < 45) {
            let calMinute = minute + 15;
            this.setState({ selectedMinute: calMinute === 0 ? "00" :calMinute })
        }
    }
    handleHourDecrease = () => {
        if (this.state.selectedDupHour > 10) {

            let value = this.state.selectedDupHour - 1
         
            this.setState({ selectedDupHour: value })
            if (value > 12) {
                let hourvalue = value - 12;
                this.setState({ selectedHour: hourvalue })
            } else {
                this.setState({ selectedHour: value })
            }

            // this.setState({selectedHour:this.state.selectedHour-1})
            if (value < 12) {
                this.setState({ activePM: false, activeAM: true })
            } else {
                this.setState({ activePM: true, activeAM: false })
            }
        }
    }
    handleMinuteDecrease = () => {
        let minute  = this.state.selectedMinute === "00" ?0:this.state.selectedMinute
        if (minute > 0) {
            let calMinute = minute - 15;
            this.setState({ selectedMinute:calMinute === 0 ? "00" :calMinute  })
        }
    }

    menu = () => {
        return (
            <div
             onClick={()=>this.handleOverlayClick("open")} 
             className="clTimePickerOverlayMain">
                <div className="clTimePickerOverlaysub">
                    <div className="tp_timeSelectionMain">

                        <div className="tp_timepickerMain">
                            <div className="clTimePickerOverlayflex">
                                <UpOutlined className="tp_updownIcons"
                                    onClick={this.handleHourIncrease} />
                                <UpOutlined className="tp_updownIcons"
                                    onClick={this.handleMinuteIncrease} />
                            </div>
                        </div>
                        <div className="tp_timeNumberain">
                            <div className="clTimePickerOverlayflex">
                                <div className="timePickerHoursSlot">
                                    {this.state.selectedHour}
                                </div>
                                <p className="tp_timepickernumSeperator">:</p>
                                <div className="timePickerHoursSlot">
                                    {this.state.selectedMinute}
                                </div>
                            </div>
                        </div>
                        <div className="tp_timepickerMain">
                            <div className="clTimePickerOverlayflex">

                                <DownOutlined className="tp_updownIcons"
                                    onClick={this.handleHourDecrease} />
                                <DownOutlined className="tp_updownIcons"
                                    onClick={this.handleMinuteDecrease} />
                            </div>
                        </div>
                    </div>
                    <div className="tp_timeAMPMMain">
                        <div className={this.state.activeAM === true ? "tp_timePickerAMSlot" :
                            "tp_timePickerAMSlotinactive"}>
                            AM
                        </div>
                        <div className={this.state.activePM === true ? "tp_timePickerPMSlot" :
                            "tp_timePickerPMSlotinactive"}>
                            PM
                        </div>
                    </div>
                </div>
                <hr style={{width:"100%"}}/>
                <Button type="primary" key="5" size="middle"
                onClick = {()=>this.handleOverlayClick("close")}
                 > OK</Button>

                {/* <div className = "tp_">

                </div> */}

            </div>
        );
    }

    handleTimeValidations = () => {
        
        // if(this.state.selectedTime !== "Select Time"){
        //     this.setState({isSelectedTimeValid:false})
        // }else{
        //     this.setState({isSelectedTimeValid:true}) 
        // }
    }

    render() {
        const isSelectedTimeValid = this.props.isTimeValid
        return (
            <Dropdown overlay={this.menu} trigger={['click']}
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}>
                <div onClick={this.handleDropDown}
                    className={isSelectedTimeValid === true?"clTimePickerMain":"clTimePickerMainerror" }
                    style={{width:this.props.PickerWidth,height:this.props.pickerHeight}}>
                    {this.state.selectedTime}
                </div>
            </Dropdown>

        );
    }
}

export default ClentTimePicker;