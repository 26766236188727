import BaseUrl from "./baseUrl";
import axios from 'axios';

const api = axios.create({
    baseURL: BaseUrl.rootUrl,
});

export async function  AdhocAppointmentServices(linkDetail) {
    return api.post('adhocJoin', linkDetail,{timeout: 10000}).then(response => {
      
        return response;
    }) .catch((error) => {
       
        
        return error.response;
      })
}

export async function  AdhocMeetingServices(linkDetail) {
    return api.post('meetJoin', linkDetail,{timeout: 10000}).then(response => {
        
        return response;
    }) .catch((error) => {
        
        
        return error.response;
      })
}
