import React  from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import VideoCalling from "../pages/videoCalling";
import Login from "../pages/login";
import Appointment from "../pages/appointment";
import Adhoc from "../pages/adhoc";
import Admin from "../pages/Admin";
import MeetingDetails from "../components/meeting"

const CompRoute = () => (
    <Router>
         <Switch>
            
            <Route exact path="/" component={Login} />
            <Route exact path="/VideoCalling" component={VideoCalling} />
            <Route exact path="/Appointment" component={Appointment} />
            <Route exact path="/Adhoc" component={Adhoc} />
            <Route exact path="/AdhocMeet" component={Adhoc} />
            <Route exact path="/Admin" component={Admin} />
            <Route exact path="/MeetingDetails" component={MeetingDetails} />

            
            
         </Switch>
    </Router>
)
export default CompRoute;