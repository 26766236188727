import React, { Component } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import "../css/timePicker.css"

class MeetExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        
        return (
            <div>
                <table id="meet" className="table" style={{ display: "none" }}>
                    <thead>
                        <tr className = "headingsExcelRow">
                            <th>Organizer</th>
                            {/* <th>Doctor's Name</th> */}
                            <th>Confirmed Appointment Time</th>
                            <th>Participant's Names</th>
                            <th>Created By</th>
                            <th>Created Time</th>
                            <th>Modified By</th>
                            <th>Modified Time</th>
                            <th>Purpose</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>              {
                        this.props.data.map((appt, index) => {
                            return <tr key={index}>
                                <td>{appt.Organizer}</td>
                                {/* <td >{appt.drName}</td> */}
                                <td >{appt.appointmentDateTime}</td>
                                <td >{appt.particpants}</td>
                                <td >{appt.createdBy}</td>
                                <td >{appt.createTime}</td>
                                <td >{appt.modBy === "Unknown" ? "" : appt.modBy}</td>
                                <td>{appt.modTime}</td>
                                <td>{appt.data}</td>
                                <td>{appt.status}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                <div style={{display:"flex",flexDirection:"row-reverse"}}>
                    <ReactHTMLTableToExcel
                        className="btn btn-info"
                        table="meet"
                        filename="AppointmentsExcel"
                        sheet="Sheet"
                        buttonText="Download Excel" />
                </div>

            </div>
        );
    }
}

export default MeetExcel;