/* Let CRA handle linting for sample app */
import React, { Component } from 'react';


import CompRoute from "./routerLink/router"
import './App.css';



class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
     
    };
    
  }

 

  
  render() {
  

    return (
      <div className="App">
        
        <CompRoute/>
       
     
    </div>
    );
  }
}

export default App;
