import BaseUrl from "../services/baseUrl";
import axios from 'axios';

const api = axios.create({
    baseURL: BaseUrl.rootUrl,
});

export async function  UserLoginService(userCredentials) {
    return api.post('login', userCredentials,{timeout: 10000}).then(response => {
       
        return response;
    }) .catch((error) => {
    
        
        return error.response;
      })
}