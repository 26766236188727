import React, { Component } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import "../css/timePicker.css"

class ApptExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div>
                <table id="emp" className="table" style={{ display: "none" }}>
                    <thead>
                        <tr className = "headingsExcelRow">
                            <th>Patient's Name</th>
                            <th>Doctor's Name</th>
                            <th>Consultant's Name</th>
                            <th>Confirmed Appointment Time</th>
                            <th>Created By</th>
                            <th>Created Time</th>
                            <th>Modified By</th>
                            <th>Modified Time</th>
                            <th>Purpose</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>              {
                        this.props.data.map((appt, index) => {
                            return <tr key={index}>
                                <td>{appt.ptName}</td>
                                <td >{appt.drName}</td>
                                <td >{appt.consName}</td>
                                <td >{appt.appointmentDateTime}</td>
                                <td >{appt.createdBy}</td>
                                <td >{appt.createTime}</td>
                                <td >{appt.modBy === "Unknown" ? "" : appt.modBy}</td>
                                <td>{appt.modTime}</td>
                                <td>{appt.data}</td>
                                <td>{appt.status}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                <div style={{display:"flex",flexDirection:"row-reverse"}}>
                    <ReactHTMLTableToExcel
                        className="btn btn-info"
                        table="emp"
                        filename="AppointmentsExcel"
                        sheet="Sheet"
                        buttonText="Download Excel" />
                </div>

            </div>
        );
    }
}

export default ApptExcel;